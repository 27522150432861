import React from "react";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";
import QuestionForm from 'presentation/pages/questions/elements/form';

class QuestionAdd extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      form: {
        questionType: 1,
        questionTypeId: 1
      },
      actionLoading: false,
      error: false,
      message: '',
      data: [],
      questionOptions: [],
      questionInformations: [],
      questionDisciplineInformations: [],
      totalOptions: 5,
      totalInformations: 1,
      totalDisciplineInformations: 1
    };
  }

  render() {
    let { loading, error, message, actionLoading } = this.state;

    return (
      <div>
        {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
        {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
        {loading === false && (
          <QuestionForm
            component={this}
          />
        )
        }
      </div>
    );
  };
}

export default QuestionAdd;
