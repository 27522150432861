import React from "react";
import { Container, Row, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";
import PageModal from "presentation/components/page-modal";

import AddStudentModal from "presentation/pages/classrooms/elements/add-student-modal";

import { handleResultData } from "presentation/utils/functions";

import activitiesService from "presentation/services/activities";

class ActivityStudentsMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      actionLoading: false,
      error: false,
      message: '',
      data: [],
      showConfirmModal: false,
      showAddStudentModal: false,
      targetDeleteId: null,
    };
    this.loadData = this.loadData.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  async componentDidMount() {
    const { id, classroomId, disciplineId } = this.props.match.params
    await this.loadData(id, classroomId, disciplineId);
  }

  loadData = async (id, classroomId, disciplineId) => {

    await activitiesService.activityStudents(id).then((result) => {
      let response = handleResultData(result);
      if (response.error == true) {
        this.setState({
          loading: false,
          actionLoading: false,
          error: true,
          message: response.message
        });
      } else {
        this.setState({ data: response, loading: false });
      }
    });

  }

  openModal = (id) => {
    this.setState({ targetDeleteId: id, showConfirmModal: true });
  }

  closeModal = () => {
    this.setState({ showConfirmModal: false, targetDeleteId: null });
  }

  openAddStudentModal = () => {
    this.setState({ showAddStudentModal: true });
  }

  closeAddStudentModal = () => {
    this.setState({ showAddStudentModal: false });
  }


  render() {
    const { id } = this.props.match.params;

    const { loading, actionLoading, error, message, data, showConfirmModal, showAddStudentModal, classroom } = this.state;

    const { closeModal, openModal, openAddStudentModal, closeAddStudentModal, loadData } = this;

    const activityData = data.length > 0 ? data[0] : {
      classroomName: null,
      disciplineName: null,
      activityId: null,
      activityTitle: null,
      activityStartDate: null,
      activityEndDate: null,
    };

    return (
      <div>
        {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
        {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
        {loading === false && error === false && data.length <= 0 && <PageAlert className="m-b-20" message={"Sem informações"} description={"Não há dados para exibir no momento..."} type="info" dismissible />}
        {loading === false && (
          <Container>
            <Row>
              <Container>
                {activityData.activityId != null && (
                  <div>
                    <h6>{activityData.classroomName} / {activityData.disciplineName} / {activityData.activityTitle}</h6>
                    <p>
                      Data de abertura: {activityData.activityStartDate || " - "}
                      <br />
                      Encerra em: {activityData.activityEndDate || " - "}
                    </p>
                  </div>
                )}
                {data.length > 0 && (
                  <Table bordered borderless className="mt-4">
                    <thead>
                      <tr>
                        <th>Matrícula</th>
                        <th>Nome</th>
                        <th>Realizada?</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((element, index) => {
                        var hasAnswer = element.activityWasAnswered == true;
                        return (
                          <tr key={element.studentId}>
                            <td>{element.studentRegistration}</td>
                            <td>{element.studentName}</td>
                            <td>{hasAnswer == true ? ' sim ' : 'não'}</td>
                            <td className="text-end">
                              <Button as={Link} to={`/atividades/entregas/ver/${element.activityId}/${element.studentId}`} disabled={!hasAnswer} variant={`outline-${hasAnswer ? 'primary' : 'secondary'}`} >Ver</Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}

              </Container>
              <div className="mt-3">
                <Button as={Link} to={`/atividades`} className="float-end" variant="secondary">Mudei de ideia</Button>
              </div>
            </Row>
            <AddStudentModal
              show={showAddStudentModal}
              onClose={closeAddStudentModal}
              loadData={loadData}
              entityId={id}
              entityName={""}
            />
          </Container>
        )}
      </div>
    );
  };
}

export default ActivityStudentsMain;
