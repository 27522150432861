import React from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";

import PageAlert from "presentation/components/page-alert";
import PageLoadingIndicator from "presentation/components/page-loading-indicator";

import { handleChange } from "presentation/utils/functions";

import { connect } from "react-redux";

import classroomsService from "presentation/services/classrooms";
import disciplinesService from "presentation/services/disciplines";


class ActivitiesFilterForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            localLoading: false,
            localError: false,
            message: '',
            disciplines: [],
            classrooms: []
        }

    }

    async componentDidMount() {
        await this.loadLocalData();
    }

    loadLocalData = async () => {

        const { sessionParams, classroomsRestriction, disciplinesRestriction } = this.props;

        this.setState({
            localLoading: true
        });

        let classrooms = [];
        let disciplines = [];
        let localError = false;
        let localLoading = true;
        let errorMessages = [];
        let message = '';

        try {

            let classroomsFilter = { academicPeriodId: [parseInt(sessionParams.currentAcademicPeriod)] };

            if (classroomsRestriction) {
                classroomsFilter.classroomId = classroomsRestriction;
            }

            await classroomsService.search(classroomsFilter).then((classroomsResults) => {
                if (classroomsResults.error == true) {
                    localError = true;
                    errorMessages.push("Não foi possível recuperar as turmas...");
                } else {
                    classrooms = classroomsResults;
                }
            });

            var disciplinesFilter = {};
            if (disciplinesRestriction) {
                disciplinesFilter.disciplineId = disciplinesRestriction;
            }

            await disciplinesService.search(disciplinesFilter).then((disciplinesResults) => {
                if (disciplinesResults.error == true) {
                    localError = true;
                    errorMessages.push("Não foi possível recuperar as disciplinas...");
                } else {
                    disciplines = disciplinesResults;
                }
            });

            if (localError === true) {
                localLoading = false;
                message = errorMessages.join(" / ");
            } else {
                localError = false;
                localLoading = false;
            }

            this.setState({
                ...this.state,
                classrooms: classrooms,
                disciplines: disciplines,
                localError: localError,
                localLoading: localLoading,
                message: message
            });
        } catch (err) {
            this.setState({
                localLoading: false,
                localError: true,
                message: err.message
            });
        }

    }

    render() {

        let { component, onSubmit, submitTitle } = this.props;

        const { filterForm, loading } = component.state;
        const {
            classrooms,
            disciplines,
            localError,
            localLoading,
            message
        } = this.state;

        var hasEmptyFilterOptions = classrooms.length == 0 || disciplines.length == 0;

        return (
            <>
                {loading === true || localLoading === true && <PageLoadingIndicator loadingText={"Carregando informações..."} />}
                {(localLoading === false && localError === true) && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                {
                    loading === false && localLoading === false && (
                        <Form>
                            <Container>
                                <Row>
                                    <Col xs={12}>
                                        <div className="form mt-2">
                                            <Form.Label htmlFor="searchText">Que no título contenham o texto</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="searchText"
                                                name="searchText"
                                                defaultValue={filterForm.searchText}
                                                onChange={(e) => handleChange(e, component, 'filterForm')}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="form mt-2">
                                            <Form.Label htmlFor={`classroomId`}>Turma</Form.Label>
                                            <Form.Select
                                                aria-label="Turma"
                                                id={`classroomId`}
                                                name={`classroomId`}
                                                multiple={true}
                                                data-field-name="classroomId"
                                                defaultValue={filterForm.classroomId}
                                                onChange={(e) => handleChange(e, component, 'filterForm')}
                                            >
                                                {classrooms.map((e, i) => { return <option key={e.name} value={e.id}>{e.name}</option> })}
                                            </Form.Select>
                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="form mt-2">
                                            <Form.Label htmlFor={`disciplineId`}>Disciplina</Form.Label>
                                            <Form.Select
                                                aria-label="Turma"
                                                id={`disciplineId`}
                                                name={`disciplineId`}
                                                multiple={true}
                                                data-field-name="disciplineId"
                                                defaultValue={filterForm.disciplineId}
                                                onChange={(e) => handleChange(e, component, 'filterForm')}
                                            >
                                                {disciplines.map((e, i) => { return <option key={e.name} value={e.id}>{e.name}</option> })}
                                            </Form.Select>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                            <Container>
                                <Row className="mt-2">
                                    <div>
                                        <Button disabled={loading || localLoading || localError || hasEmptyFilterOptions} variant="primary" className="float-end" onClick={() => onSubmit()}>{submitTitle || "Filtrar"}</Button>
                                    </div>
                                </Row>
                            </Container>
                        </Form >
                    )
                }
            </>
        );
    };
}

//Configs Redux para este component
//
//Define quais atributos vou pegar do
//state do Redux
const mapStateToProps = (state) => {
    const { data } = state.sessionParams;
    return { sessionParams: data };
};


//Define quais ações esse component
//vai usar para interagir com o Redux
const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesFilterForm);