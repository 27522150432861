import React from "react";
import { Container, Row, Table, Button, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";
import PageModal from "presentation/components/page-modal";

import { handleResultData } from "presentation/utils/functions";
import { connect } from "react-redux";

import activitiesService from "presentation/services/activities";
import ActivitiesFilterForm from "presentation/pages/activities/elements/filter";

import { getUser } from 'presentation/utils/authentication';

class ActivityMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterForm: {
        searchText: '',
        disciplineId: [],
        classroomId: [],
      },
      loading: true,
      actionLoading: false,
      error: false,
      message: '',
      data: [],
      showConfirmModal: false,
      targetDeleteId: null,
      userClassrooms: [],
      userDisciplines: []
    };
    this.loadData = this.loadData.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
  }

  async componentDidMount() {
    await this.loadData();
  }
  async componentDidUpdate(prevProps, prevState) {
    const { sessionParams } = this.props;
    const { sessionParams: previousSessionParams } = prevProps;

    if (sessionParams.currentAcademicPeriod != previousSessionParams.currentAcademicPeriod) {
      await this.loadData();
    }
  }

  loadData = async () => {
    const user = getUser();
    const { sessionParams } = this.props;

    if (sessionParams.currentAcademicPeriod) {
      let userClassrooms = [];
      let userDisciplines = [];

      let startLoading = (user.isTeacher == false || (user.isTeacher == true && user.teacherInfo.length > 0));

      if (startLoading == true) {
        if (user.teacherInfo) {
          user.teacherInfo.map((classroom, i) => {
            if (classroom) {
              userClassrooms.push(classroom.id);
              classroom.disciplines.map((discipline, j) => {
                if (discipline) {
                  userDisciplines.push(discipline.id);
                }
              });
            }
          });
        }

        this.setState({ loading: true, userClassrooms: userClassrooms, userDisciplines: userDisciplines });

        var postData = { ...this.state.filterForm };

        postData.academicPeriodId = [parseInt(sessionParams.currentAcademicPeriod)];

        if (postData.disciplineId.length <= 0 && userDisciplines) {
          postData.disciplineId = userDisciplines;
        }


        if (postData.classroomId.length <= 0 && userClassrooms) {
          postData.classroomId = userClassrooms;
        }

        await activitiesService.search(postData).then((result) => {
          let handledResult = handleResultData(result);

          this.setState({ data: handledResult, loading: false });
        });
      } else {
        this.setState({ data: [], loading: false });
      }

    }

  }

  openModal = (id) => {
    this.setState({ targetDeleteId: id, showConfirmModal: true });
  }

  closeModal = () => {
    this.setState({ showConfirmModal: false, targetDeleteId: null });
  }

  deleteItem = () => {
    const { loadData } = this;
    if (this.state.targetDeleteId) {
      this.setState({ loading: true, actionLoading: true });
      activitiesService.remove(this.state.targetDeleteId).then((result) => {
        this.setState({ loading: false, actionLoading: false, targetDeleteId: null, showConfirmModal: false }, () => {
          loadData();
        });
      });
    }
  }

  render() {

    const { loading, actionLoading, error, message, data, showConfirmModal, userClassrooms, userDisciplines } = this.state;

    const { deleteItem, closeModal, openModal, loadData } = this;
    const user = getUser();

    let showContent = (user.isTeacher == false || (user.isTeacher == true && user.teacherInfo.length > 0));

    return (
      <div>
        {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
        {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
        {loading === false && error === false && showContent == true && data.length <= 0 && <PageAlert className="m-b-20" message={"Sem informações"} description={"Não há dados para exibir no momento..."} type="info" dismissible />}
        {loading === false && error === false && showContent == false && <PageAlert className="m-b-20" message={"Sem informações"} description={"Não há dados para exibir no momento... Por favor, verifique o seu cadastro..."} type="info" dismissible />}
        {loading === false && showContent == true && (
          <Container>
            <Row>
              <h4>Listagem de atividades <Button as={Link} to={'/atividades/adicionar'} variant="outline-primary" className="float-end">Adicionar</Button></h4>

              <Accordion className="mt-4">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Filtros</Accordion.Header>
                  <Accordion.Body>
                    <ActivitiesFilterForm component={this} onSubmit={loadData} classroomsRestriction={userClassrooms} disciplinesRestriction={userDisciplines} />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Container>
                <Table bordered borderless className="mt-4">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Título</th>
                      <th>Turma</th>
                      <th>Disciplina</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 && data.map((element, index) => {
                      return (
                        <tr key={element.id}>
                          <td>{element.id}</td>
                          <td>{element.title}</td>
                          <td>{element.classroom.name}</td>
                          <td>{element.discipline.name}</td>
                          <td className="text-end">
                            <Button as={Link} to={`/atividades/entregas/${element.id}/${element.classroomId}/${element.disciplineId}`} variant="outline-primary">Entregas</Button>{' '}
                            <Button as={Link} to={`/atividades/editar/${element.id}`} variant="outline-primary">Editar</Button>{' '}
                            <Button variant="outline-secondary" onClick={() => openModal(element.id)}>Remover</Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Container>
            </Row>
            <PageModal
              show={showConfirmModal}
              onClose={closeModal}
              onConfirm={deleteItem} />
          </Container>
        )}
      </div>
    );
  };
}

//Configs Redux para este component
//
//Define quais atributos vou pegar do
//state do Redux
const mapStateToProps = (state) => {
  const { data } = state.sessionParams;
  return { sessionParams: data };
};


//Define quais ações esse component
//vai usar para interagir com o Redux
const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(ActivityMain);