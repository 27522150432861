import questionDisciplineInformationsService from "presentation/services/question-discipline-informations";
import { isNullOrEmpty } from "presentation/utils/functions";
import moment from "moment";

// Question informations methods

export const yearsList = () => {
    let currentYear = moment().format("yyyy");
    let output = [{ year: currentYear }];

    for (let i = 1; i <= 50; i++) {
        output.push({ year: moment(currentYear).add(-i, 'year').format("yyyy") });
    }
    return output;
}

// Carrega uma alternativa no state
export const loadQuestionDisciplineInformation = (key, component) => {
    const { questionDisciplineInformations } = component.state;
    let output = undefined;

    if (questionDisciplineInformations.length > 0) {
        var questionInformation = questionDisciplineInformations.find(x => { return x.key == key });
        output = questionInformation !== undefined ? questionInformation : undefined;
    }

    return output;
}

// Carrega uma alternativa no state
export const getDisciplineTopics = (disciplineId, component) => {
    const { questionTopics } = component.state;
    let output = [];
    if (questionTopics !== undefined) {
        if (questionTopics.length > 0) {
            var disciplineTopics = questionTopics.find(x => { return x.disciplineId == disciplineId });
            output = disciplineTopics !== undefined ? disciplineTopics.items : [];
        }
    }


    return output;
}

//Carrega as informações de um campo específico da alternativa no state
export const questionDisciplineInformationsFormValue = (key, formField, component) => {

    let output = undefined;

    var questionInformation = loadQuestionDisciplineInformation(key, component);

    output = questionInformation !== undefined ? questionInformation[formField] : undefined;

    return output;
}

//Tratativas para quando um campo muda de valor (geral)
export const handleChangeQuestionDisciplineInformations = (key, event, component) => {
    const { value, checked, type } = event.target;

    const name = event.target.getAttribute('data-field-name');

    const changeValue = type == "checkbox" ? checked : value;

    handleFieldChange(key, name, changeValue, component);
}

//Tratativas para quando um campo muda de valor (editor de texto)
export const handleChangeQuestionDisciplineInformationsEditor = (key, formField, editor, component) => {
    const changeValue = editor.getData();

    handleFieldChange(key, formField, changeValue, component);
}

//Tratativas para quando um campo muda de valor (elemento arvore)
export const handleChangeQuestionDisciplineInformationsTree = (key, formField, selectedNodes, component) => {
    handleFieldChange(key, formField, selectedNodes[0], component);
}

//Método genérico para acompanhar tratativas de mudança de valor dos campos
export const handleFieldChange = (key, field, value, component) => {
    var questionInformation = loadQuestionDisciplineInformation(key, component);

    questionInformation[field] = value;

    const newQuestionDisciplineInformations = changeQuestionDisciplineInformationsList(component, questionInformation);

    let finalInformations = beforeChangeQuestionDisciplineInformationsState(key, field, value, newQuestionDisciplineInformations, component);

    component.setState({
        questionDisciplineInformations: finalInformations
    });
}

//Método para executar tratamentos antes da mudança para o novo state do formulário
export const beforeChangeQuestionDisciplineInformationsState = (key, changedField, changeValue, newQuestionDisciplineInformations, component) => {
    const { form } = component.state;
    let output = newQuestionDisciplineInformations;

    const allowMultipleAnswers = (form.allowMultipleInformationsAnswers || false);

    if (changedField == 'isCorrect' && changeValue == true && allowMultipleAnswers == false) {
        output = newQuestionDisciplineInformations.map((option) => {
            let mapOutput = option;
            if (option.key !== key) {
                mapOutput[changedField] = false;
            }
            return mapOutput;
        });
    }

    return output;
}

//Prapara o objeto do state para a atualização com as informações das alternativas da questão
export const changeQuestionDisciplineInformationsList = (component, targetInformation, remove = false) => {
    let { questionDisciplineInformations } = component.state;

    let optionIndex = questionDisciplineInformations.findIndex(option => option.key == targetInformation.key);

    if (remove === true) {
        let elementId = questionDisciplineInformations[optionIndex]['id'] || undefined;

        if (elementId) {
            questionDisciplineInformations[optionIndex]['isDeleteRequested'] = true;
            questionDisciplineInformations[optionIndex]['key'] = 'delete-requested-' + new Date().getTime();
        } else {
            questionDisciplineInformations.splice(optionIndex, 1);
        }
    } else {
        questionDisciplineInformations[optionIndex] = targetInformation;
    }

    return questionDisciplineInformations;
}


//Prepara as requisições para administrar as alternativas das questões
export const prepareInformationsRequests = (questionEntity, questionDisciplineInformationsData) => {
    let promises = undefined;
    let requests = [];

    questionDisciplineInformationsData.map((e, index) => {

        let postData = {
            questionId: questionEntity.id
        };

        if (e.id) {
            postData.id = e.id;
            postData.insertAt = e.insertAt;

            if (e.isDeleteRequested) {
                postData.isDeleteRequested = true;
            }
        }

        postData = {
            ...postData,
            questionTopicId: e.questionTopicId,
            disciplineId: e.disciplineId
        }

        requests.push(postData);

    });

    if (requests.length > 0) {
        promises = requests.map(async (element, index) => {
            //Salva os dados da progressão
            if (element['isDeleteRequested']) {
                await questionDisciplineInformationsService.remove(element['id']);
            } else {
                await questionDisciplineInformationsService.save(element);
            }
        });
    };

    return promises;

}

// ----------------------------------------

//Verificação de cadastro feito incorretamente


//Alguma informação de questão incompleta
export const hasInvalidDisciplineInformation = (component) => {
    const { questionDisciplineInformations } = component.state;
    let output = false;
    let count = 0;

    if (questionDisciplineInformations.length > 0) {
        let invalidInformations = [];

        questionDisciplineInformations.map((option) => {
            if (option.isDeleteRequested !== true) {
                let completedInformation = !isNullOrEmpty(option.disciplineId) && !isNullOrEmpty(option.questionTopicId);
                if (!completedInformation) {
                    invalidInformations.push(option);
                }
            }
        });
        count = invalidInformations.length;
    } else {
        //sem questões preenchidas
        count++;
    }

    if (count > 0) {
        output = true;
    }

    return output;

}

const questionDisciplineInformationsFunctions = {
    prepareInformationsRequests,
    getDisciplineTopics,
    loadQuestionDisciplineInformation,
    questionDisciplineInformationsFormValue,
    handleChangeQuestionDisciplineInformations,
    handleChangeQuestionDisciplineInformationsEditor,
    handleChangeQuestionDisciplineInformationsTree,
    beforeChangeQuestionDisciplineInformationsState,
    changeQuestionDisciplineInformationsList,
    hasInvalidDisciplineInformation
}

export default questionDisciplineInformationsFunctions;