import React from "react";
import { Container, Row, Col, Table, Button, Card, CardGroup } from "react-bootstrap";


import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";
import { handleResultData } from "presentation/utils/functions";

import StudentActivitiesModal from 'presentation/pages/principal/dashboards/students/elements/activities-modal';

import activitiesService from "presentation/services/activities";

class StudentDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            actionLoading: false,
            error: false,
            message: '',
            data: [],
            showStudentActivitiesModal: false,
            activities: [],
            classroomName: '',
            disciplineName: ''
        };
        this.loadData = this.loadData.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {
        const { formatData } = this;

        this.setState({ loading: true });

        await activitiesService.studentActivities().then((result) => {
            let handledResult = handleResultData(result);
            let data = formatData(handledResult);
            this.setState({ data: data, loading: false });
        });
    }

    formatData = (rawData) => {
        let data = [];

        rawData.map((e, i) => {
            if (!data[e.classroomId]) {
                data[e.classroomId] = {
                    id: e.classroomId,
                    name: e.classroomName,
                    disciplines: []
                };
            }

            if (!data[e.classroomId].disciplines[e.disciplineId]) {
                data[e.classroomId].disciplines[e.disciplineId] = {
                    id: e.disciplineId,
                    name: e.disciplineName,
                    activities: []
                };
            }

            data[e.classroomId].disciplines[e.disciplineId].activities.push(e);
        });
        return data;
    }

    openAcitvitiesModal = (classroomName, disciplineName, activities) => {
        this.setState({
            showStudentActivitiesModal: true,
            classroomName: classroomName,
            disciplineName: disciplineName,
            activities: activities
        });
    }

    closeActivitiesModal = () => {
        this.setState({
            showStudentActivitiesModal: false,
            classroomName: '',
            disciplineName: '',
            activities: []
        });
    }


    render() {

        const { openAcitvitiesModal, closeActivitiesModal } = this;
        const { loading, actionLoading, error, message, data, showStudentActivitiesModal, activities, classroomName, disciplineName } = this.state;

        return (
            <div>
                {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
                {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                {loading === false && error === false && data.length <= 0 && <PageAlert className="m-b-20" message={"Sem informações"} description={"Não há dados para exibir no momento..."} type="info" dismissible />}
                {loading === false && (
                    <Container>
                        {data.map((classroom, index) => {
                            return (
                                <Row key={index}>
                                    <h4 className={index > 0 ? "mt-5" : ""} >{classroom.name}</h4>
                                    {classroom.disciplines.map((discipline, disciplineIndex) => {
                                        return (
                                            <Col key={disciplineIndex} className="mt-4" lg={4} md={4}>
                                                <Card border={discipline.border}>
                                                    <Card.Body>
                                                        <Card.Title>{discipline.name}</Card.Title>
                                                        <Card.Text>
                                                            {discipline.description}
                                                        </Card.Text>
                                                    </Card.Body>
                                                    <Card.Footer>
                                                        <small className="text-muted">Atividades em aberto: {discipline.activities.filter(x => x.activityWasAnswered == false).length}</small>
                                                        <Button className="float-end" size="sm" variant="outline-primary" onClick={(e) => openAcitvitiesModal(classroom.name, discipline.name, discipline.activities)}>ver atividades</Button>
                                                    </Card.Footer>
                                                </Card>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            );
                        })}
                        <StudentActivitiesModal
                            show={showStudentActivitiesModal}
                            onClose={closeActivitiesModal}
                            activities={activities}
                            classroomName={classroomName}
                            disciplineName={disciplineName}
                        />
                    </Container>
                )}
            </div>
        );
    };
}

export default StudentDashboard;