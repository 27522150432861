import { get, post, put, destroy } from "presentation/utils/api";

//Define a rota base desse serviço
const baseRoute = "QuestionTopics";


export const findAll = async () => {
    return await get(baseRoute);
};

export const findAllWithDisciplineAsRoot = async () => {
    return await get(baseRoute, "/discipline_as_root");
};

export const findById = async (id) => {
    return await get(baseRoute, "/" + id);
};

export const findByDisciplineId = async (id) => {
    return await get(baseRoute, "/discipline/" + id);
};

export const add = async (postData) => {
    var data = JSON.stringify(postData);
    return await post(baseRoute, "", data);
};

export const update = async (postData) => {
    var data = JSON.stringify(postData);
    return await put(baseRoute, "", data);
};

export const save = async (postData) => {
    if (postData.id !== undefined) {
        return await update(postData);
    } else {
        return await add(postData);
    }
};

export const remove = async (id) => {
    return await destroy(baseRoute, "", id);
};


const questionTopicsService = {
    findAll,
    findAllWithDisciplineAsRoot,
    findById,
    findByDisciplineId,
    add,
    update,
    save,
    remove,
}

export default questionTopicsService;