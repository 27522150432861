import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

function LoadingIndicator(props) {
  const { loadingText } = props;

  return (
    <Spinner animation="border" role="status">
      <span className="visually-hidden">{loadingText ? loadingText : 'Carregando informações...'} </span>
    </Spinner>
  );
}


export default LoadingIndicator;