import React from "react";
import PageHeader from "presentation/pages/layout/elements/page-header";
import { Container } from "react-bootstrap";


class NotFound extends React.Component {
  render() {
    let { location } = this.props;
    return (
      <Container>
        <h3>Página não encontrada...</h3>
      </Container>
    );
  };
}

export default NotFound;
