import { createReducer } from '@reduxjs/toolkit';
import { add, clear } from 'presentation/redux/modules/systemAlert/actions';


const initialState = {
    actionFeedbackMessage: null,
};

const systemAlert = createReducer(initialState, {
    [add]: (state, action) => {
        const { payload } = action;
        state.actionFeedbackMessage = payload;

    },
    [clear]: (state, action) => {
        state.actionFeedbackMessage = null;

    }
});

export default systemAlert;