import React from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";

import disciplinesService from "presentation/services/disciplines";
import questionTopicsService from "presentation/services/question-topics";

import RemoveAction from "presentation/pages/questions/elements/remove-action";

import Tree from "rc-tree";
import "rc-tree/assets/index.css"
import { treeView } from "presentation/utils/functions";

import {
    changeQuestionDisciplineInformationsList,
    loadQuestionDisciplineInformation,
    handleChangeQuestionDisciplineInformations,
    handleChangeQuestionDisciplineInformationsTree,
    questionDisciplineInformationsFormValue,
    getDisciplineTopics
} from 'presentation/pages/questions/functions/question-discipline-informations';


class QuestionDisciplineInformationsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            localLoading: true,
            localError: false,
            errorMessages: [],
            message: ''
        };

    }

    async componentDidMount() {
        await this.loadData();
        this.mountInformations();
    }


    loadQuestionTopics = async () => {
        const { component } = this.props;
        const { disciplines } = this.state;

        let disciplineIdSearch = [];
        let questionTopics = [];

        let errors = [];
        let hasError = false;
        let message = '';



        if (disciplines[0] !== undefined) {
            for (let i = 0; i < disciplines.length; i++) {
                disciplineIdSearch.push(disciplines[i].id);
            }

        }



        if (disciplineIdSearch.length > 0) {

            for (let i = 0; i < disciplineIdSearch.length; i++) {
                let disciplineId = disciplineIdSearch[i];

                await questionTopicsService.findByDisciplineId(disciplineId).then((result) => {
                    if (result.error == true) {
                        hasError = true;
                        errors.push(result.message);
                    } else {
                        if (result.length == 0) {
                            hasError = true;
                            errors.push(`Não há tópicos cadastrados para disciplina código ${disciplineId}...`);

                        } else {
                            questionTopics.push({ disciplineId: disciplineId, items: result });
                        }
                    }
                });

            }

        } else {
            hasError = true;
            errors.push("Erro ao localizar a disciplina para carregar a lista de tópicos...");
        }

        if (errors.length > 0) {
            message = errors.join(" <br />");
        }
        let me = this;
        component.setState({ ...component.state, questionTopics: questionTopics }, () => {
            me.setState({
                localLoading: false,
                localError: hasError,
                message: message
            });
        });
    }

    loadData = async () => {
        let disciplines = [];
        let localError = false;
        let localLoading = true;
        let errorMessages = [];
        let message = '';

        try {

            await disciplinesService.findAll().then(async (result) => {
                if (result.error == true) {
                    localError = true;
                    errorMessages.push("Não foi possível recuperar a listagem de disciplinas...");
                } else {
                    if (result.length <= 0) {
                        localError = true;
                        errorMessages.push("Por favor, cadastre disciplinas antes de cadastrar uma questão...");
                    } else {
                        disciplines = result;
                    }
                }
            });


            if (localError === true) {
                localLoading = false;
                message = errorMessages.join(" / ");
            }

            let me = this;
            this.setState({ ...this.state, disciplines: disciplines, localError: localError, localLoading: localLoading, message: message }, async () => {
                if (localError === false) {
                    await me.loadQuestionTopics();
                }
            });
        } catch (err) {
            this.setState({
                localLoading: false,
                localError: true,
                message: err.message
            });
        }

    }

    addOption = () => {
        let { component } = this.props;
        let { totalDisciplineInformations } = component.state;
        component.setState({ ...component.state, totalDisciplineInformations: totalDisciplineInformations + 1 }, () => this.mountInformations());
    }

    removeAction = (key) => {
        let { component } = this.props;

        let { totalDisciplineInformations } = component.state;

        let questionDisciplineInformations = changeQuestionDisciplineInformationsList(component, { key: key }, true);

        //reorganiza as keys
        let index = 0;
        questionDisciplineInformations.map((element, i) => {
            if (!element.isDeleteRequested) {
                element.key = index + 1;
                index++;
            }
            return element;
        });

        component.setState({ ...component.state, totalDisciplineInformations: totalDisciplineInformations - 1, questionDisciplineInformations: questionDisciplineInformations }, () => this.mountInformations());
    }

    mountInformations = () => {
        let { component } = this.props;

        const { totalDisciplineInformations, questionDisciplineInformations } = component.state;


        const deleteRequested = questionDisciplineInformations.filter(x => x.isDeleteRequested === true);

        let newQuestionDisciplineInformations = [];

        for (let i = 0; i < totalDisciplineInformations; i++) {

            let infoKey = i + 1;

            //Default values

            let defaultId = null;
            let defaultQuestionId = null;
            let defaultDisciplineId = null;
            let defaultQuestionTopicId = null;

            let infoId = defaultId;
            let infoQuestionId = defaultQuestionId;
            let infoQuestionTopicId = defaultQuestionTopicId;
            let infoDisciplineId = defaultDisciplineId;

            let questionDisciplineInformation = loadQuestionDisciplineInformation(infoKey, component);

            if (questionDisciplineInformation !== undefined) {

                infoId = questionDisciplineInformation['id'] || defaultId;
                infoQuestionId = questionDisciplineInformation['questionId'] || defaultQuestionId;
                infoQuestionTopicId = questionDisciplineInformation['questionTopicId'] || defaultQuestionTopicId;
                infoDisciplineId = questionDisciplineInformation['disciplineId'] || defaultDisciplineId;
            }

            newQuestionDisciplineInformations.push({
                id: infoId,
                key: infoKey,
                questionId: infoQuestionId,
                questionTopicId: infoQuestionTopicId,
                disciplineId: infoDisciplineId
            });
        }

        let finalQuestionDisciplineInformations = [...newQuestionDisciplineInformations, ...deleteRequested];

        component.setState({ ...component.state, questionDisciplineInformations: finalQuestionDisciplineInformations });
    }

    render() {
        let { component } = this.props;
        const { questionDisciplineInformations, loading, totalDisciplineInformations } = component.state;
        const { disciplines, localLoading, localError, message } = this.state;

        const { addOption, removeAction } = this;

        const validQuestionDisciplineInformations = questionDisciplineInformations.filter(x => !x.isDeleteRequested);

        return (
            <>
                {loading === true || localLoading === true && <PageLoadingIndicator loadingText={"Carregando informações..."} />}
                {(localLoading === false && localError === true) && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                {
                    loading === false && localLoading === false && (
                        <Container fluid className="question-informations-container system-management">
                            {validQuestionDisciplineInformations.map((element, index) => {
                                let key = element.key;
                                let disciplineId = questionDisciplineInformationsFormValue(key, 'disciplineId', component);
                                let questionTopicId = questionDisciplineInformationsFormValue(key, 'questionTopicId', component);

                                let filteredTopics = getDisciplineTopics(disciplineId, component);

                                let disciplineQuestionTopics = treeView(filteredTopics);

                                var expandedKeys = filteredTopics.map((x) => { return x.id });
                                var checkedKeys = questionTopicId ? [questionTopicId] : [];

                                const deleteIsDisabled = (totalDisciplineInformations < 2);

                                return (
                                    <Container key={`question-information-${key}`} className={`mt-2 question-information-container`}>
                                        <Row>
                                            <Col className="mt-3 mb-3">
                                                <div>
                                                    <Row>
                                                        <Col>
                                                            <RemoveAction
                                                                component={this}
                                                                optionKey={key}
                                                                loading={loading}
                                                                deleteIsDisabled={deleteIsDisabled}
                                                                removeAction={removeAction} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="form mt-2">
                                                    <Form.Label htmlFor={`disciplineId-${key}`}>Disciplina</Form.Label>
                                                    <Form.Select
                                                        aria-label="Disciplina da questão"
                                                        defaultValue={disciplineId}
                                                        id={`disciplineId-${key}`}
                                                        name={`disciplineId-${key}`}
                                                        data-field-name="disciplineId"
                                                        onChange={(event) => handleChangeQuestionDisciplineInformations(key, event, component)}
                                                        aria-describedby={`disciplineIdHelpBlock-${key}`}
                                                    >
                                                        <option value="">Selecione uma disciplina</option>
                                                        {disciplines.map((e, i) => { return <option key={e.id} value={e.id}>{e.name}</option> })}
                                                    </Form.Select>
                                                    <Form.Text id={`disciplineIdHelpBlock-${key}`} muted>
                                                        Defina qual é a disciplina da questão
                                                    </Form.Text>
                                                </div>
                                                <div className="form mt-2">
                                                    <Form.Label htmlFor={`questionTopicId-${key}`}>Tópico</Form.Label>
                                                    <Tree
                                                        defaultExpandAll={true}
                                                        defaultExpandParent={true}
                                                        autoExpandParent={true}
                                                        defaultSelectedKeys={checkedKeys}
                                                        expandedKeys={expandedKeys}
                                                        height={200}
                                                        itemHeight={20}
                                                        style={{ border: '1px solid #eee', padding: '10px' }}
                                                        treeData={disciplineQuestionTopics}
                                                        onSelect={(selectedNodes, e) => handleChangeQuestionDisciplineInformationsTree(key, 'questionTopicId', selectedNodes, component)}
                                                    />
                                                    <Form.Text id={`questionTopicIdHelpBlock-${key}`} muted>
                                                        Defina qual é o tópico da questão
                                                    </Form.Text>
                                                </div>

                                            </Col>

                                        </Row>
                                    </Container>
                                );
                            })}
                            <div className="text-center">
                                <Button className="mt-5 mb-5" variant="primary" onClick={addOption}> Adicionar outro tópico</Button>
                            </div>
                        </Container>
                    )
                }
            </>
        );
    };
}

export default QuestionDisciplineInformationsList;