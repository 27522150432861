import { post } from "presentation/utils/api";

//Define a rota base desse serviço
const baseRoute = "FileStorage";

export const editorUpload = async (postData) => {
    return await post(baseRoute, "editor", postData);
};



const fileStorageService = {
    editorUpload
}

export default fileStorageService;