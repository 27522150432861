import activityQuestionsService from "presentation/services/activity-questions";
import activityQuestionsPointsService from "presentation/services/activity-questions-points";
import { isNullOrEmpty } from "presentation/utils/functions";

// Question informations methods

// Carrega uma alternativa no state
export const loadQuestionInformation = (key, component) => {
    let { questionInformations } = component.state;
    let output = undefined;
    if (questionInformations.length > 0) {
        var questionInformation = questionInformations.find(x => { return x.key == key });
        output = questionInformation !== undefined ? questionInformation : undefined;
    }

    return output;
}


//Carrega as informações de um campo específico da alternativa no state
export const questionInformationsFormValue = (key, formField, component) => {

    let output = undefined;

    var questionInformation = loadQuestionInformation(key, component);

    output = questionInformation !== undefined ? questionInformation[formField] : undefined;

    return output;
}

//Tratativas para quando um campo muda de valor (geral)
export const handleChangeQuestionInformations = (key, event, component, extraInfoOnCreate = {}) => {
    const { value, checked, type } = event.target;

    const name = event.target.getAttribute('data-field-name');

    const changeValue = type == "checkbox" ? checked : value;

    handleFieldChange(key, name, changeValue, component, extraInfoOnCreate);
}


//Método genérico para acompanhar tratativas de mudança de valor dos campos
export const handleFieldChange = (key, field, value, component, extraInfoOnCreate = {}) => {
    var questionInformation = loadQuestionInformation(key, component);

    if (typeof (questionInformation) === 'undefined') {
        questionInformation = { key: key, ...extraInfoOnCreate };
    }

    questionInformation[field] = value;

    const newQuestionInformations = changeQuestionInformationsList(component, questionInformation);

    component.setState({
        questionInformations: newQuestionInformations
    });
}


//Prapara o objeto do state para a atualização com as informações das alternativas da questão
export const changeQuestionInformationsList = (component, targetInformation, remove = false) => {
    let { questionInformations } = component.state;

    let optionIndex = questionInformations.findIndex(option => option.key == targetInformation.key);

    if (optionIndex < 0) {
        optionIndex = questionInformations.length;
    }

    if (remove === true) {
        let elementId = questionInformations[optionIndex]['id'] || undefined;

        if (elementId) {
            questionInformations[optionIndex]['isDeleteRequested'] = true;
            questionInformations[optionIndex]['key'] = 'delete-requested-' + new Date().getTime();
        } else {
            questionInformations.splice(optionIndex, 1);
        }
    } else {
        questionInformations[optionIndex] = targetInformation;
    }
    return questionInformations;
}


//Prepara as requisições para administrar as questões da atividade
export const prepareInformationsRequests = async (activityEntity, questionsData) => {
    let requests = [];

    questionsData.map((question, index) => {

        let postData = {
            activityId: activityEntity.id,
            disciplineId: activityEntity.disciplineId,
            classroomId: activityEntity.classroomId,
        };

        postData = {
            ...postData,
            questionId: question.id,
            order: question.order,
            isRemove: question.isDeleteRequested,
            id: question.activityQuestionId || null,
        }

        requests.push(postData);

    });
    return await activityQuestionsService.batchManagement(requests)
}

export const preparePointsInformationsRequests = async (activityEntity, pointsData) => {
    let requests = [];

    pointsData.map((pointItem, index) => {

        let postData = {
            activityId: activityEntity.id,
            disciplineId: activityEntity.disciplineId,
            classroomId: activityEntity.classroomId,
        };

        postData = {
            ...postData,
            questionId: pointItem.questionId,
            questionOptionId: pointItem.questionOptionId,
            points: pointItem.points || 0,
            id: pointItem.activityQuestionPointId || null,
        }

        requests.push(postData);

    });
    return await activityQuestionsPointsService.batchManagement(requests);
}

// ----------------------------------------

//Verificação de cadastro feito incorretamente


//Alguma informação de questão incompleta
export const hasInvalidInformation = (component) => {
    const { questionInformations } = component.state;
    let output = false;
    let count = 0;

    if (questionInformations.length > 0) {
        let invalidInformations = [];

        questionInformations.map((option) => {
            if (option.isDeleteRequested !== true) {
                let completedInformation = !isNullOrEmpty(option.questionOriginId) && !isNullOrEmpty(option.questionOriginVariationId);
                if (!completedInformation) {
                    invalidInformations.push(option);
                }
            }

        });
        count = invalidInformations.length;
    } else {
        //sem questões preenchidas
        count++;
    }

    if (count > 0) {
        output = true;
    }

    return output;

}

const activityQuestionFunctions = {
    prepareInformationsRequests,
    preparePointsInformationsRequests,
    loadQuestionInformation,
    questionInformationsFormValue,
    handleChangeQuestionInformations,
    changeQuestionInformationsList,
    hasInvalidInformation
}

export default activityQuestionFunctions;