import questionInformationsService from "presentation/services/question-informations";
import { isNullOrEmpty } from "presentation/utils/functions";

// Question informations methods

// Carrega uma alternativa no state
export const loadQuestionInformation = (key, component) => {
    const { questionInformations } = component.state;
    let output = undefined;

    if (questionInformations.length > 0) {
        var questionInformation = questionInformations.find(x => { return x.key == key });
        output = questionInformation !== undefined ? questionInformation : undefined;
    }

    return output;
}

// Carrega uma alternativa no state
export const getDisciplineTopics = (disciplineId, component) => {
    const { questionTopics } = component.state;
    let output = [];
    if (questionTopics !== undefined) {
        if (questionTopics.length > 0) {
            var disciplineTopics = questionTopics.find(x => { return x.disciplineId == disciplineId });
            output = disciplineTopics !== undefined ? disciplineTopics.items : [];
        }
    }


    return output;
}

//Carrega as informações de um campo específico da alternativa no state
export const questionInformationsFormValue = (key, formField, component) => {

    let output = undefined;

    var questionInformation = loadQuestionInformation(key, component);

    output = questionInformation !== undefined ? questionInformation[formField] : undefined;

    return output;
}

//Tratativas para quando um campo muda de valor (geral)
export const handleChangeQuestionInformations = (key, event, component) => {
    const { value, checked, type } = event.target;

    const name = event.target.getAttribute('data-field-name');

    const changeValue = type == "checkbox" ? checked : value;

    handleFieldChange(key, name, changeValue, component);
}

//Tratativas para quando um campo muda de valor (editor de texto)
export const handleChangeQuestionInformationsEditor = (key, formField, editor, component) => {
    const changeValue = editor.getData();

    handleFieldChange(key, formField, changeValue, component);
}

//Método genérico para acompanhar tratativas de mudança de valor dos campos
export const handleFieldChange = (key, field, value, component) => {
    var questionInformation = loadQuestionInformation(key, component);

    questionInformation[field] = value;

    const newQuestionInformations = changeQuestionInformationsList(component, questionInformation);

    let finalInformations = beforeChangeQuestionInformationsState(key, field, value, newQuestionInformations, component);

    component.setState({
        questionInformations: finalInformations
    });
}

//Método para executar tratamentos antes da mudança para o novo state do formulário
export const beforeChangeQuestionInformationsState = (key, changedField, changeValue, newQuestionInformations, component) => {
    const { form } = component.state;
    let output = newQuestionInformations;

    const allowMultipleAnswers = (form.allowMultipleInformationsAnswers || false);

    if (changedField == 'isCorrect' && changeValue == true && allowMultipleAnswers == false) {
        output = newQuestionInformations.map((option) => {
            let mapOutput = option;
            if (option.key !== key) {
                mapOutput[changedField] = false;
            }
            return mapOutput;
        });
    }

    return output;
}

//Prapara o objeto do state para a atualização com as informações das alternativas da questão
export const changeQuestionInformationsList = (component, targetInformation, remove = false) => {
    let { questionInformations } = component.state;

    let optionIndex = questionInformations.findIndex(option => option.key == targetInformation.key);

    if (remove === true) {
        let elementId = questionInformations[optionIndex]['id'] || undefined;

        if (elementId) {
            questionInformations[optionIndex]['isDeleteRequested'] = true;
            questionInformations[optionIndex]['key'] = 'delete-requested-' + new Date().getTime();
        } else {
            questionInformations.splice(optionIndex, 1);
        }
    } else {
        questionInformations[optionIndex] = targetInformation;
    }

    return questionInformations;
}


//Prepara as requisições para administrar as alternativas das questões
export const prepareInformationsRequests = (questionEntity, questionInformationsData) => {
    let promises = undefined;
    let requests = [];

    questionInformationsData.map((e, index) => {

        let postData = {
            questionId: questionEntity.id
        };

        if (e.id) {
            postData.id = e.id;
            postData.insertAt = e.insertAt;

            if (e.isDeleteRequested) {
                postData.isDeleteRequested = true;
            }
        }

        postData = {
            ...postData,
            questionOriginId: e.questionOriginId,
            questionOriginVariationId: e.questionOriginVariationId,
            questionNumber: e.questionNumber
        }

        requests.push(postData);

    });

    if (requests.length > 0) {
        promises = requests.map(async (element, index) => {
            //Salva os dados da progressão
            if (element['isDeleteRequested']) {
                await questionInformationsService.remove(element['id']);
            } else {
                await questionInformationsService.save(element);
            }
        });
    };

    return promises;

}

// ----------------------------------------

//Verificação de cadastro feito incorretamente


//Alguma informação de questão incompleta
export const hasInvalidInformation = (component) => {
    const { questionInformations } = component.state;
    let output = false;
    let count = 0;

    if (questionInformations.length > 0) {
        let invalidInformations = [];

        questionInformations.map((option) => {
            if (option.isDeleteRequested !== true) {
                let completedInformation = !isNullOrEmpty(option.questionOriginId) && !isNullOrEmpty(option.questionOriginVariationId);
                if (!completedInformation) {
                    invalidInformations.push(option);
                }
            }

        });
        count = invalidInformations.length;
    } else {
        //sem questões preenchidas
        count++;
    }

    if (count > 0) {
        output = true;
    }

    return output;

}

const questionInformationsFunctions = {
    prepareInformationsRequests,
    getDisciplineTopics,
    loadQuestionInformation,
    questionInformationsFormValue,
    handleChangeQuestionInformations,
    handleChangeQuestionInformationsEditor,
    beforeChangeQuestionInformationsState,
    changeQuestionInformationsList,
    hasInvalidInformation
}

export default questionInformationsFunctions;