import React from "react";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";
import ActivityForm from 'presentation/pages/activities/elements/form';

class ActivityAdd extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      form: {},
      actionLoading: false,
      error: false,
      message: '',
      selectedQuestions: [],
      questionInformations: [],
    };
  }

  render() {
    const { id } = this.props.match.params;
    let { loading, error, message, actionLoading } = this.state;

    return (
      <div>
        {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
        {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
        {loading === false && (
          <ActivityForm
            component={this}
            classroomId={id}
          />
        )
        }
      </div>
    );
  };
}

export default ActivityAdd;
