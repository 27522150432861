import React from "react";
import { Container, Row, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import employeesService from "presentation/services/employees";
import usersService from "presentation/services/users";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";

import EmployeeUserForm from 'presentation/pages/employees/elements/employee-user-form';

import { handleChange } from "presentation/utils/functions";

class EmployeeUserInfo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      form: {},
      actionLoading: false,
      error: false,
      message: '',
    };
    this.loadData = this.loadData.bind(this);

  }

  async componentDidMount() {
    const { id } = this.props.match.params
    await this.loadData(id);
  }

  loadData = async (id) => {
    await employeesService.findById(id).then(async (result) => {
      var employeeInfo = {
        id: result.id,
        registration: result.registration
      }

      if (typeof (result.person) !== 'undefined') {


        employeeInfo = {
          ...employeeInfo,
          personId: result.person.id,
          userId: result.person.userId,
          name: result.person.name,
          displayName: result.person.displayName
        }
        if (result.person.userId) {
          await usersService.findById(result.person.userId).then((userResult) => {
            employeeInfo.email = userResult.email;
            employeeInfo.username = userResult.username;
          });
        }

      }
      this.setState({ form: employeeInfo, loading: false });
    });
  }

  goTo = (destination) => {
    const { history } = this.props;

    history.push(destination);
  };

  handleSubmit = async () => {

    this.setState({ loading: true, actionLoading: true, error: '' });

    const {
      email,
      newPassword,
      userId
    } = this.state.form;

    if (!email) {
      this.setState({ loading: false, error: true, message: "Por favor, preencha um email válido..." });
    } else {
      if (!userId && !newPassword) {
        this.setState({ loading: false, error: true, message: "Por favor, preencha uma senha..." });
      } else {
        let postData = this.state.form;
        postData.password = postData.newPassword;

        try {
          const response = await employeesService.saveUser(postData);
          if (response.error == true) {
            this.setState({
              loading: false,
              actionLoading: false,
              error: true,
              message: response.message
            });
          } else {

            this.goTo("/colaboradores");
          }
        } catch (err) {
          this.setState({
            loading: false,
            actionLoading: false,
            error: true,
            message: err.message
          });
        }
      }

    }
  };

  render() {
    let { location } = this.props;
    let { loading, error, message, actionLoading, form } = this.state;
    let { handleSubmit } = this;

    return (
      <div>
        {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
        {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
        {loading === false && (
          <Container>
            <Row>
              <EmployeeUserForm
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                loading={loading}
                form={form}
                component={this}
              />
            </Row>
          </Container>
        )
        }
      </div>
    );
  };
}

export default EmployeeUserInfo;
