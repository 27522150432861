import React from "react";
import { Container, Row, Table, Button, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import parse from 'html-react-parser';

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";
import Paginator from "presentation/components/paginator";
import PageModal from "presentation/components/page-modal";

import { handleResultData } from "presentation/utils/functions";

import questionsService from "presentation/services/questions";
import QuestionFilterForm from "presentation/pages/questions/elements/filter";

class QuestionMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterForm: {
        isPaginatedSearch: true,
        pageNumber: 1,
        searchText: '',
        disciplineId: [],
        questionTopicId: [],
        questionDifficultyId: [],
        questionOriginId: [],
        questionOriginVariationId: [],
        questionYear: [],
        questionTypeId: [],
      },
      loading: true,
      actionLoading: false,
      error: false,
      message: '',
      data: { items: [], totalItems: 0 },
      showConfirmModal: false,
      targetDeleteId: null,
    };
    this.loadData = this.loadData.bind(this);
    this.loadPage = this.loadPage.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
  }

  async componentDidMount() {
    await this.loadData();
  }

  clearFilter = () => {
    this.setState({
      ...this.state,
      filterForm: {
        isPaginatedSearch: true,
        pageNumber: 1,
        searchText: '',
        disciplineId: [],
        questionTopicId: [],
        questionDifficultyId: [],
        questionOriginId: [],
        questionOriginVariationId: [],
        questionYear: [],
        questionTypeId: [],
      }
    }, () => { this.loadData() });
  };

  loadData = async () => {

    this.setState({ loading: true });
    let postData = this.state.filterForm;
    await questionsService.search(postData).then((result) => {
      let handledResult = handleResultData(result);

      this.setState({ data: handledResult, loading: false });
    });
  }

  loadPage = (page) => {

    this.setState({
      ...this.state,
      filterForm: {
        ...this.state.filterForm,
        pageNumber: page
      }
    }, () => this.loadData());
  }

  openModal = (id) => {
    this.setState({ targetDeleteId: id, showConfirmModal: true });
  }

  closeModal = () => {
    this.setState({ showConfirmModal: false, targetDeleteId: null });
  }

  deleteItem = () => {
    const { loadData } = this;
    if (this.state.targetDeleteId) {
      this.setState({ loading: true, actionLoading: true });
      questionsService.remove(this.state.targetDeleteId).then((result) => {
        this.setState({ loading: false, actionLoading: false, targetDeleteId: null, showConfirmModal: false }, () => {
          loadData();
        });
      });
    }
  }

  render() {

    const { loading, actionLoading, error, message, data, showConfirmModal } = this.state;

    const { deleteItem, closeModal, openModal, loadPage, clearFilter } = this;
    let hasData = data.items != null && data.items.length > 0;
    return (
      <div>
        {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
        {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
        {loading === false && error === false && data.length <= 0 && <PageAlert className="m-b-20" message={"Sem informações"} description={"Não há dados para exibir no momento..."} type="info" dismissible />}
        {loading === false && (
          <Container>
            <Row>
              <h4>Listagem de questões <Button as={Link} to={'/questoes/adicionar'} variant="outline-primary" className="float-end">Adicionar</Button></h4>

              <Accordion className="mt-4">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Filtros</Accordion.Header>
                  <Accordion.Body>
                    <QuestionFilterForm component={this} onSubmit={() => loadPage(1)} onClearFilter={clearFilter} />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Container>

                {hasData == true && (
                  <div className="mt-4 mb-4" >
                    <Paginator paginatorData={data} goToPage={loadPage} />
                  </div>
                )}
                <h6 className="float-end">Total de questões: {data.totalItems}</h6>
                <div className="clearfix"></div>
                <Table bordered borderless className="mt-4">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Enunciado</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {hasData == true && data.items.map((element, index) => {
                      return (
                        <tr key={element.id}>
                          <td>{element.id}</td>
                          <td><div className="table-cell-height-limited">{parse(element.title)}</div></td>
                          <td className="text-end">
                            <Button as={Link} to={`/questoes/editar/${element.id}`} variant="outline-primary">Editar</Button>{' '}
                            <Button variant="outline-secondary" onClick={() => openModal(element.id)}>Remover</Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {hasData == true && <Paginator paginatorData={data} goToPage={loadPage} />}
              </Container>
            </Row>
            <PageModal
              show={showConfirmModal}
              onClose={closeModal}
              onConfirm={deleteItem} />
          </Container>
        )}
      </div>
    );
  };
}

export default QuestionMain;
