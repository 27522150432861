import React from "react";
import PageHeader from "presentation/pages/layout/elements/page-header";
import { Container, Row, Form, Button } from "react-bootstrap";

import questionTopicsService from "presentation/services/question-topics";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";

import QuestionTopicForm from 'presentation/pages/question-topics/elements/form';

class QuestionTopicAdd extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      form: {},
      actionLoading: false,
      error: false,
      message: '',
      data: [],
    };
  }

  goTo = (destination) => {
    const { history } = this.props;

    history.push(destination);
  };

  handleSubmit = async () => {

    this.setState({ loading: true, actionLoading: true, error: '' });

    const {
      name,
      disciplineId
    } = this.state.form;

    if (!name || !disciplineId) {
      this.setState({ loading: false, error: true, message: "Por favor, preencha um nome e ou uma disciplina..." });
    } else {
      let postData = this.state.form;
      try {
        const response = await questionTopicsService.add(postData);
        if (response.error == true) {
          this.setState({
            loading: false,
            actionLoading: false,
            error: true,
            message: response.message
          });
        } else {

          this.goTo("/topicos-questoes");
        }
      } catch (err) {
        this.setState({
          loading: false,
          actionLoading: false,
          error: true,
          message: err.message
        });
      }
    }
  };

  render() {
    let { location } = this.props;
    let { loading, error, message, actionLoading, form } = this.state;
    let { handleSubmit } = this;


    return (
      <div>
        {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
        {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
        {loading === false && (
          <Container>
            <Row>
              <QuestionTopicForm
                handleSubmit={handleSubmit}
                loading={loading}
                form={form}
                component={this}
              />
            </Row>
          </Container>
        )
        }
      </div>
    );
  };
}

export default QuestionTopicAdd;
