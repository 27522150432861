import React from "react";
import { Container, Row, Col, Button, Card, Form, } from "react-bootstrap";
import { Link } from "react-router-dom";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";
import { handleChange, handleResultData } from "presentation/utils/functions";
import { getUser, login, identifyRoles, mergeWithSession } from "presentation/utils/authentication";

import usersService from "presentation/services/users";

import disciplinesEmployeesService from "presentation/services/disciplines-employees";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      actionLoading: false,
      error: false,
      validated: false,
      message: '',
      form: {}
    };
  }
  componentDidMount() {
    var currentUser = getUser();
    if (currentUser !== null) {
      this.goTo('/');
    }
  }
  goTo = (destination) => {
    const { history } = this.props;

    history.push(destination);
  };

  formatTeacherData = (rawData) => {
    let data = [];
    rawData.map((e, i) => {
      if (!data[e.classroomId]) {
        data[e.classroomId] = {
          id: e.classroomId,
          name: e.classroomName,
          disciplines: []
        };
      }

      if (!data[e.classroomId].disciplines[e.disciplineId]) {
        data[e.classroomId].disciplines[e.disciplineId] = {
          id: e.disciplineId,
          name: e.disciplineName,
          activities: []
        };
      }

      data[e.classroomId].disciplines[e.disciplineId].activities.push(e);
    });
    return data;
  }

  handleSubmit = async (event) => {
    let { form } = this.state;
    let { formatTeacherData } = this;
    const formElement = event.currentTarget;

    event.preventDefault();
    event.stopPropagation();

    if (formElement.checkValidity() === false) {
      this.setState({ validated: true });
      return;
    }

    this.setState({ loading: true, actionLoading: true, error: '' });

    let postData = form;
    try {
      const response = await usersService.authenticate(postData);

      if (response.error == true) {
        this.setState({
          loading: false,
          actionLoading: false,
          error: true,
          message: response.message
        });
      } else {
        login(response);

        var userInfo = identifyRoles(response);

        if (userInfo.isTeacher) {
          await disciplinesEmployeesService.employeeDisciplines().then((result) => {
            let handledResult = handleResultData(result);
            let data = formatTeacherData(handledResult);
            userInfo.teacherInfo = data
          });
        }
        mergeWithSession(userInfo);

        this.goTo("/");
      }
    } catch (err) {
      this.setState({
        loading: false,
        actionLoading: false,
        error: true,
        message: err.message
      });
    }
  }

  render() {
    const { loading, actionLoading, error, message, validated } = this.state;

    const { handleSubmit } = this;

    return (
      <Container fluid id="login-page">
        {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
        {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
        {/* {loading === false && error === false && data.length <= 0 && <PageAlert className="m-b-20" message={"Sem informações"} description={"Não há dados para exibir no momento..."} type="info" dismissible />} */}
        {loading === false && (
          <Container>
            <Row>
              <Col xs={12} lg={7}>
                <h2>Basico</h2>
                <p className="lead mb-5"> - Olá! <br />Para ter acesso a plataforma <br />preencha suas credenciais...</p>
              </Col>
              <Col xs={12} lg={5}>
                <Card id="login-form">
                  <Card.Body>
                    <Card.Title className="mb-4">Login</Card.Title>
                    <Form noValidate validated={validated} onSubmit={(e) => handleSubmit(e)}>
                      <Form.Group as={Row} className="mb-4">
                        <Col sm="12">
                          <Form.Control required type="email" name="email" defaultValue={this.state.form.email} placeholder="Seu e-mail" onChange={(e) => handleChange(e, this)} />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-4">
                        <Col sm="12">
                          <Form.Control required type="password" name="password" defaultValue={this.state.form.password} placeholder="Sua senha" onChange={(e) => handleChange(e, this)} />
                        </Col>
                      </Form.Group>
                      <Button className="float-end mt-5" variant="primary" type="submit"> Enviar</Button>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container >
        )
        }
      </Container>
    );
  };
}

export default LoginPage;