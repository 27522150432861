import styled from "styled-components";

export const TableStyles = styled.div`
                padding-top: 1rem;
                padding-bottom: 1rem;

                table {
                    width: 100%;
                    border-spacing: 0;
                    border: 1px solid #ced4da;

                    tr {
                    :last-child {
                        td {
                        border-bottom: 0;
                        }
                    }
                    }

                    th,
                    td {
                    margin: 0;
                    padding: 0.5rem;
                    border-bottom: 1px solid #ced4da;
                    border-right: 1px solid #ced4da;

                    :last-child {
                        border-right: 0;
                    }
                    }
                }
                `;
export default TableStyles;