import React from "react";
import { Container, Row, Col, Table, Button, Card, CardGroup } from "react-bootstrap";


import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";

import { getUser } from 'presentation/utils/authentication';


class TeachersDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            actionLoading: false,
            error: false,
            message: '',
            data: [],
            activities: [],
            classroomName: '',
            disciplineName: ''
        };
    }


    render() {
        const user = getUser();
        const { loading, actionLoading, error, message } = this.state;
        const data = user.teacherInfo;

        return (
            <div>
                {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
                {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                {loading === false && error === false && data.length <= 0 && <PageAlert className="m-b-20" message={"Sem informações"} description={"Não há dados para exibir no momento..."} type="info" dismissible />}
                {loading === false && (
                    <Container>
                        {data.map((classroom, index) => {
                            return classroom !== null && (
                                <Row key={index}>
                                    <h4 className={index > 0 ? "mt-5" : ""} >{classroom.name}</h4>
                                    {classroom.disciplines.map((discipline, disciplineIndex) => {
                                        return discipline !== null && (
                                            <Col key={disciplineIndex} className="mt-4" lg={4} md={4}>
                                                <Card border={discipline.border}>
                                                    <Card.Body>
                                                        <Card.Title>{discipline.name}</Card.Title>
                                                        <Card.Text>
                                                            {discipline.description}
                                                        </Card.Text>
                                                    </Card.Body>
                                                    <Card.Footer>
                                                        <small className="text-muted">Aproveitamento: {discipline.successRate || 0}%</small>
                                                    </Card.Footer>
                                                </Card>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            );
                        })}
                    </Container>
                )}
            </div>
        );
    };
}

export default TeachersDashboard;