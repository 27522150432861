import React from "react";
import PageHeader from "presentation/pages/layout/elements/page-header";
import { Container, Row, Form, Button } from "react-bootstrap";

import classroomsService from "presentation/services/classrooms";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";

import ClassroomForm from 'presentation/pages/classrooms/elements/form';

class ClassroomAdd extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      form: {},
      actionLoading: false,
      error: false,
      message: '',
      data: [],
    };
  }

  goTo = (destination) => {
    const { history } = this.props;

    history.push(destination);
  };

  handleSubmit = async () => {

    this.setState({ loading: true, actionLoading: true, error: '' });

    const {
      name,
      courseId,
      academicPeriodId
    } = this.state.form;

    if (!name || !courseId || !academicPeriodId) {
      this.setState({ loading: false, error: true, message: "Por favor, preencha um nome e ou um curso e/ou um período acadêmico..." });
    } else {
      let postData = this.state.form;
      try {
        const response = await classroomsService.add(postData);
        if (response.error == true) {
          this.setState({
            loading: false,
            actionLoading: false,
            error: true,
            message: response.message
          });
        } else {

          this.goTo("/turmas");
        }
      } catch (err) {
        this.setState({
          loading: false,
          actionLoading: false,
          error: true,
          message: err.message
        });
      }
    }
  };

  render() {
    let { location } = this.props;
    let { loading, error, message, actionLoading, form } = this.state;
    let { handleSubmit } = this;


    return (
      <div>
        {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
        {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
        {loading === false && (
          <Container>
            <Row>
              <ClassroomForm
                handleSubmit={handleSubmit}
                loading={loading}
                form={form}
                component={this}
              />
            </Row>
          </Container>
        )
        }
      </div>
    );
  };
}

export default ClassroomAdd;
