import React from "react";

import activitiesService from "presentation/services/activities";
import activitiesQuestionsService from "presentation/services/activity-questions";
import activitiesQuestionsPointsService from "presentation/services/activity-questions-points";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";

import ActivityForm from 'presentation/pages/activities/elements/form';

class ActivityEdit extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      form: {},
      actionLoading: false,
      error: false,
      message: '',
      selectedQuestions: [],
      questionInformations: [],
    };

    this.loadData = this.loadData.bind(this);

  }

  async componentDidMount() {
    const { id } = this.props.match.params
    await this.loadData(id);
  }

  loadData = async (id) => {
    var activityId = parseInt(id);
    var actityResults = await activitiesService.findById(activityId);
    var actityQuestionsResults = await activitiesQuestionsService.search({ activityId: [activityId] });
    var actityQuestionPointsResults = await activitiesQuestionsPointsService.findByActivityId(activityId);

    var selectedQuestions = [];
    var questionInformations = [];

    await Promise.all([actityResults, actityQuestionsResults, actityQuestionPointsResults]);

    actityQuestionsResults.map((e, i) => {
      selectedQuestions.push({ ...e.question, order: e.order, activityQuestionId: e.id });
    });

    actityQuestionPointsResults.map((e, i) => {
    
      questionInformations.push({ ...e, activityQuestionPointId: e.id, key: e.questionOptionId });
    });
   
    this.setState({ form: actityResults, selectedQuestions: selectedQuestions, questionInformations: questionInformations, loading: false });
  }

  render() {
    const { id, classroomId } = this.props.match.params;
    let { loading, error, message, actionLoading } = this.state;
    return (
      <div>
        {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
        {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
        {loading === false && (
          <ActivityForm
            component={this}
            classroomId={classroomId}
            activityId={id}
          />
        )
        }
      </div>
    );
  };
}

export default ActivityEdit;
