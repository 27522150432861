import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import PageHeader from 'presentation/pages/layout/elements/page-header';

import ScrollToTop from "presentation/components/scroll-to-top";

import paths from "presentation/config/paths";

import { isAuthenticated } from "presentation/utils/authentication";

const RenderRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            rest.verifyCredentials ?
                (
                    isAuthenticated() ? (
                        RenderComponent(props, <Component {...props} />, rest)
                    ) : (
                        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
                    )
                ) :
                RenderComponent(props, <Component {...props} />, rest)

        }
    />
);

const RenderComponent = (props, component, rawProps) => {

    return (
        <div className="App">
            <div style={{ display: 'flex', height: '100vh' }}>
                <main style={{ flex: 1 }}>
                    {rawProps.template != 'external' && <PageHeader history={props.history} location={props.location} hideBreadcrumb={rawProps.hideBreadcrumb} />}
                    {component}
                </main>
            </div>
        </div>
    );
}

const Routes = () => (
    <HashRouter>
        <ScrollToTop />
        <Switch>
            {paths.map((item) => {
                return item.isPrivate ? <RenderRoute key={item.path} {...item} verifyCredentials /> : <RenderRoute key={item.path} {...item} />;
            })}
        </Switch>
    </HashRouter>
);

export default Routes;