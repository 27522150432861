import React from "react";
import { Modal, Button, Table, Form } from "react-bootstrap";

import StudentFilterForm from "presentation/pages/students/elements/filter";
import { handleResultData, handleTaleCheckAll, handleCheckTableItems } from "presentation/utils/functions";

import studentsService from "presentation/services/students";
import classroomStudentsService from "presentation/services/classroom-students";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";

import { CLASSROOM_STUDENT_STATUS_ACTIVE } from 'presentation/model/constants';

class AddStudentModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            filterForm: {},
            selectedItems: [],
            data: []
        };

        this.tBodyRef = React.createRef(null);
    }

    searchData = async () => {
        this.setState({ loading: true });
        let postData = this.state.filterForm;
        await studentsService.search(postData).then((result) => {
            let handledResult = handleResultData(result);
            this.setState({ data: handledResult, loading: false });
        });
    }

    onSubmit = async () => {

        let { entityId, onClose, loadData } = this.props;
        let { selectedItems } = this.state;

        this.setState({ loading: true, error: '' });


        if (selectedItems.length < 1) {
            this.setState({ loading: false, error: true, message: "Por favor, selecione ao menos um aluno..." });
        } else {

            let postData = selectedItems.map((e, i) => {
                return {
                    classroomId: entityId,
                    studentId: e,
                    classroomStudentsStatusId: CLASSROOM_STUDENT_STATUS_ACTIVE
                }
            });

            try {
                const response = await classroomStudentsService.batchAdd(postData);
                if (response.error == true) {
                    this.setState({
                        loading: false,
                        error: true,
                        message: response.message
                    });
                } else {
                    this.setState({
                        loading: false,
                        error: '',
                    }, () => {
                        onClose();
                        loadData(entityId);
                    });
                }
            } catch (err) {
                this.setState({
                    loading: false,
                    actionLoading: false,
                    error: true,
                    message: err.message
                });
            }
        }
    };

    render() {
        let { show, onClose, entityName } = this.props;
        let { data, loading, selectedItems, error, message } = this.state;
        const { searchData, onSubmit, tBodyRef } = this;
        const hasSelection = selectedItems.length < 1 ? false : true;
        return (
            <Modal fullscreen backdrop="static" show={show || false} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Adicionar alunos na turma {entityName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading === true && <PageLoadingIndicator loadingText={"Carregando informações..."} />}
                    {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                    {loading === false && (
                        <>
                            <StudentFilterForm
                                component={this}
                                onSubmit={() => searchData()}
                                submitTitle={'Buscar'}
                            />
                            {data.length > 0 && (
                                <>
                                    <p className="text-center"><strong>Selecione um ou mais alunos para incluir na turma</strong></p>
                                    <Table bordered borderless className="mt-4">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "5%" }}><Form.Check className="row-checkbox" type="checkbox" onChange={(e) => handleTaleCheckAll(e, this, tBodyRef)} /></th>
                                                <th>Matrícula</th>
                                                <th>Nome</th>
                                            </tr>
                                        </thead>
                                        <tbody ref={tBodyRef}>
                                            {data.length > 0 && data.map((element, index) => {
                                                return (
                                                    <tr key={element.id}>
                                                        <td><Form.Check className="row-checkbox" type="checkbox" onChange={(e) => handleCheckTableItems(e, element.id, this)} data-option-value={element.id} /></td>
                                                        <td>{element.registration}</td>
                                                        <td>{element.person.name}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </>
                            )}
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={loading} variant="secondary" onClick={onClose}>
                        Mudei de ideia
                    </Button>
                    <Button disabled={loading || !hasSelection} variant="primary" onClick={() => onSubmit()}>
                        Salvar
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
}

export default AddStudentModal;