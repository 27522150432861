import React, { useMemo, useState } from "react";
import {
    closestCenter,
    DndContext,
    DragOverlay,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
    SortableContext,
    verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { useTable } from "react-table";
import { DraggableTableRow } from "presentation/components/draggable-table/draggable-table-row";
import { StaticTableRow } from "presentation/components/draggable-table/static-table-row";

export function DraggableTable({ columns, data, setData }) {
    const [activeId, setActiveId] = useState();
    const items = useMemo(() => data?.map(({ id }) => id), [data]);
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({
        columns,
        data
    });
    const sensors = useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {})
    );

    function handleDragStart(event) {
        setActiveId(event.active.id);
    }

    function handleDragEnd(event) {
        setData(event);
        setActiveId(null);
    }

    function handleDragCancel() {
        setActiveId(null);
    }

    const selectedRow = useMemo(() => {
        if (!activeId) {
            return null;
        }
        const row = rows.find(({ original }) => original.id === activeId);
        prepareRow(row);
        return row;
    }, [activeId, rows, prepareRow]);

    // Render the UI for your table
    return (
        <DndContext
            sensors={sensors}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
            onDragCancel={handleDragCancel}
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis]}
        >
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps({ style: column.style })}>{column.render("Header")}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    <SortableContext items={items} strategy={verticalListSortingStrategy}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return <DraggableTableRow key={row.original.id} row={row} />;
                        })}
                    </SortableContext>
                </tbody>
            </table>
            <DragOverlay>
                {activeId && (
                    <table style={{ width: "100%" }}>
                        <tbody>
                            <StaticTableRow row={selectedRow} />
                        </tbody>
                    </table>
                )}
            </DragOverlay>
        </DndContext>
    );
}
