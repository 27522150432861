import React from "react";

import activitiesService from "presentation/services/activities";
import activitiesQuestionsService from "presentation/services/activity-questions";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";

import ActivityFillForm from 'presentation/pages/activities/elements/fill-form';

class ActivityFill extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      form: {},
      actionLoading: false,
      error: false,
      message: '',
      activity: [],
      questions: [],
      answers: [],
    };

    this.loadData = this.loadData.bind(this);

  }

  async componentDidMount() {
    const { state } = this.props.location;
    const { activityId, studentId } = state || [];

    if (activityId && studentId) {
      await this.loadData(activityId);
    } else {
      this.setState({ error: true, loading: false, message: "Não foi possível identificar a atividade/aluno" });
    }

  }

  loadData = async (id) => {
    var activityId = parseInt(id);
    var actityResults = await activitiesService.findById(activityId);
    var actityQuestionsResults = await activitiesQuestionsService.search({ activityId: [activityId] });
    await Promise.all([actityResults, actityQuestionsResults]);

    this.setState({ activity: actityResults, questions: actityQuestionsResults, loading: false });
  }

  render() {
    const { location } = this.props;
    const { state } = location;
    const { activityId, studentId } = state || [];
    let { loading, error, message, actionLoading } = this.state;
    return (
      <div>
        {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
        {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
        {loading === false && error == false && (
          <ActivityFillForm
            component={this}
            activityId={activityId}
            studentId={studentId}
          />
        )
        }
      </div>
    );
  };
}

export default ActivityFill;
