import React from "react";
import { Pagination, Form } from "react-bootstrap";

class Paginator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true,
        };
    }

    setPage = (page) => {
        const { goToPage } = this.props;
        goToPage(page);
        
    }

    render() {
        let { paginatorData } = this.props;
        let pages = [];
        for (let i = 1; i <= paginatorData.totalPages; i++) {
            pages.push(i);
        }

        const { setPage } = this;

        return (
            <Pagination className="pagination-container">
                <Pagination.Prev disabled={paginatorData.hasPreviousPage !== true} onClick={() => setPage(paginatorData.currentPage - 1)} />
                <Form.Select size="sm" className="paginator-select-page m-r-1 m-l-1" value={paginatorData.currentPage} onChange={(e) => setPage(e.target.value)}>
                    {pages.map((e, i) => {
                        return <option key={e} value={e}>{`${e}`}</option>
                    })}
                </Form.Select>  <span className="m-t--5 m-r-1">/ {paginatorData.totalPages}</span>
                <Pagination.Next disabled={paginatorData.hasNextPage !== true} onClick={() => setPage(paginatorData.currentPage + 1)} />
            </Pagination>
        );
    };
}

export default Paginator;