import { get, post, put, destroy } from "presentation/utils/api";

//Define a rota base desse serviço
const baseRoute = "Activities";


export const findAll = async () => {
    return await get(baseRoute);
};

export const findById = async (id) => {
    return await get(baseRoute, "/" + id);
};

export const add = async (postData) => {
    var data = JSON.stringify(postData);
    return await post(baseRoute, "", data);
};

export const update = async (postData) => {
    var data = JSON.stringify(postData);
    return await put(baseRoute, "", data);
};

export const save = async (postData) => {
    if (postData.id !== undefined) {
        return await update(postData);
    } else {
        return await add(postData);
    }
};

export const remove = async (id) => {
    return await destroy(baseRoute, "full_delete", id);
};

export const search = async (postData) => {
    var data = JSON.stringify(postData);
    return await post(baseRoute, "search", data);
};

export const studentActivities = async (postData) => {
    var data = JSON.stringify(postData);
    return await post(baseRoute, "student_activities", data);
};

export const activityStudents = async (id) => {
    return await get(baseRoute, "activity_students" + "/" + id);
};


const activitiesService = {
    findAll,
    findById,
    add,
    update,
    save,
    remove,
    search,
    studentActivities,
    activityStudents
}

export default activitiesService;