import React from 'react';
import Header from "presentation/pages/layout/elements/header";
import Breadcrumb from "presentation/pages/layout/elements/breadcrumb";
import { Container, Row } from "react-bootstrap";

export default class PageHeader extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { location, hideBreadcrumb, history } = this.props;
        return (
            <div>
                <Header history={history} />
                <Container>
                    <Row className="mt-3">
                        {!hideBreadcrumb ? <Breadcrumb location={location} /> : ""}
                    </Row>
                </Container>
            </div>
        );
    };
}