import React from "react";

import questionsService from "presentation/services/questions";
import questionOptionsService from "presentation/services/question-options";
import questionInformationsService from "presentation/services/question-informations";
import questionDisciplineInformationsService from "presentation/services/question-discipline-informations";
import { getQuestionType } from "presentation/utils/functions";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";

import QuestionForm from 'presentation/pages/questions/elements/form';

class QuestionEdit extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      actionLoading: false,
      error: false,
      message: '',
      form: {},
      data: [],
      questionOptions: [],
      questionInformations: [],
      questionDisciplineInformations: [],
      totalOptions: 5,
      totalInformations: 1,
      totalDisciplineInformations: 1
    };

    this.loadData = this.loadData.bind(this);

  }

  async componentDidMount() {
    const { id } = this.props.match.params
    await this.loadData(id);
  }

  loadData = async (id) => {

    await questionOptionsService.findByQuestionId(id).then((questionOptionsResult) => {
      let questions = [];

      for (let i = 0; i < questionOptionsResult.length; i++) {
        let question = questionOptionsResult[i];
        question.key = i + 1;
        questions.push(question);
      }

      this.setState({ questionOptions: questions, totalOptions: questions.length });
    });

    await questionInformationsService.findByQuestionId(id).then((questionInformationsResult) => {
      let informations = [];

      for (let i = 0; i < questionInformationsResult.length; i++) {
        let information = questionInformationsResult[i];
        information.key = i + 1;
        informations.push(information);
      }

      this.setState({ questionInformations: informations, totalInformations: informations.length });
    });

    await questionDisciplineInformationsService.findByQuestionId(id).then((questionDisciplineInformationsResult) => {
      let disciplineInformations = [];

      for (let i = 0; i < questionDisciplineInformationsResult.length; i++) {
        let disciplineInformation = questionDisciplineInformationsResult[i];
        disciplineInformation.key = i + 1;
        disciplineInformations.push(disciplineInformation);
      }

      this.setState({ questionDisciplineInformations: disciplineInformations, totalDisciplineInformations: disciplineInformations.length });
    });

    await questionsService.findById(id).then((result) => {
      let questionType = getQuestionType('discursive');

      result.questionType = result.questionTypeId;

      if (result.questionTypeId == questionType.id) {
        result.isDiscursive = true;
      }

      this.setState({ form: result, loading: false });
    });


  }

  render() {
    let { loading, error, message, actionLoading } = this.state;
    return (
      <div>
        {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
        {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
        {loading === false && (
          <QuestionForm
            component={this}
          />
        )
        }
      </div>
    );
  };
}

export default QuestionEdit;
