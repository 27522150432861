import React from "react";
import { Modal, Button, Table, Form, Container } from "react-bootstrap";
import parse from 'html-react-parser';

import QuestionFilterForm from "presentation/pages/questions/elements/filter";
import { handleResultData, handleTaleCheckAll, handleCheckTableItems } from "presentation/utils/functions";

import questionsService from "presentation/services/questions";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import Paginator from "presentation/components/paginator";

class AddQuestionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            filterForm: {
                isPaginatedSearch: true,
                pageNumber: 1,
                pageSize: 1,
                searchText: '',
                disciplineId: [],
                questionTopicId: [],
                questionDifficultyId: [],
                questionOriginId: [],
                questionOriginVariationId: [],
                questionYear: [],
                questionTypeId: [],
            },
            selectedItems: [],
            data: { items: [], totalItems: 0 },
        };

        this.tBodyRef = React.createRef(null);
    }

    searchData = async () => {
        this.setState({ loading: true });
        let postData = this.state.filterForm;
        await questionsService.search(postData).then((result) => {
            let handledResult = handleResultData(result);
            this.setState({ data: handledResult, loading: false });
        });
    }

    loadPage = (page) => {

        this.setState({
            ...this.state,
            filterForm: {
                ...this.state.filterForm,
                pageNumber: page
            }
        }, () => this.searchData());
    }

    clearFilter = () => {
        this.setState({
            ...this.state,
            filterForm: {
                isPaginatedSearch: true,
                pageNumber: 1,
                pageSize: 1,
                searchText: '',
                disciplineId: [],
                questionTopicId: [],
                questionDifficultyId: [],
                questionOriginId: [],
                questionOriginVariationId: [],
                questionYear: [],
                questionTypeId: [],
            }
        }, () => { this.searchData() });
    };

    alreadyAdded = (id) => {
        let { component } = this.props;

        let { selectedQuestions } = component.state;

        if (selectedQuestions) {
            var selected = selectedQuestions.filter(x => x.id == id && !(x.isDeleteRequested == true));
            if (selected.length > 0) {
                return true;
            }
        }

        return false;
    }

    onSubmit = async () => {

        let { onClose, afterSubmit, component } = this.props;
        let { selectedItems, data } = this.state;
        let { selectedQuestions } = component.state;

        if (selectedItems.length < 1) {
            this.setState({ loading: false, error: true, message: "Por favor, selecione ao menos uma questão..." });
        } else {

            let localSelectedQuestions = [];

            let orderValues = selectedQuestions.map(a => a.order);

            let maxOrder = Math.max(...orderValues) + 1;
            for (let i = 0; i < selectedItems.length; i++) {
                var selectedItem = selectedItems[i];
                var element = data.items.filter(x => x.id == selectedItem);
                if (element) {
                    let newElement = element[0];
                    newElement.order = maxOrder + i;
                    localSelectedQuestions.push(newElement);
                }
            };


            if (localSelectedQuestions.length > 0) {
                component.setState({
                    selectedQuestions: [...selectedQuestions, ...localSelectedQuestions],
                }, () => {
                    this.setState({
                        selectedItems: [],
                        data: []
                    }, () => {
                        afterSubmit();
                        onClose();
                    });
                });
            }

        }
    };


    render() {
        let { show, onClose } = this.props;
        let { data, loading, selectedItems } = this.state;
        const { tBodyRef, alreadyAdded, loadPage, onSubmit, clearFilter } = this;
        const hasSelection = selectedItems.length < 1 ? false : true;
        let hasData = data.items != null && data.items.length > 0;

        return (
            <Modal fullscreen backdrop="static" show={show || false} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Adicionar questões</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading === true && <PageLoadingIndicator loadingText={"Carregando informações..."} />}
                    {loading === false && (
                        <Container>
                            <QuestionFilterForm
                                component={this}
                                onClearFilter={clearFilter}
                                onSubmit={() => loadPage(1)}
                                submitTitle={'Buscar'}
                            />
                            {hasData == true && (
                                <>
                                    <p className="text-center"><strong>Selecione um ou mais questões para incluir na atividade</strong></p>
                                    <div className="mt-4 mb-4" >
                                        <Paginator paginatorData={data} goToPage={loadPage} />
                                    </div>
                                    <Table bordered borderless className="mt-4">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "5%" }}><Form.Check className="row-checkbox" type="checkbox" onChange={(e) => handleTaleCheckAll(e, this, tBodyRef)} /></th>
                                                <th>Enunciado</th>
                                                <th>Código</th>
                                            </tr>
                                        </thead>
                                        <tbody ref={tBodyRef}>
                                            {data.items.map((element, index) => {
                                                let alreadySelected = alreadyAdded(element.id);
                                                return (
                                                    <tr key={element.id} className={alreadySelected ? 'active-row' : ''} >
                                                        <td><Form.Check className="row-checkbox" type="checkbox" defaultChecked={alreadySelected} disabled={alreadySelected} onChange={(e) => handleCheckTableItems(e, element.id, this)} data-option-value={element.id} /></td>
                                                        <td><div className="table-cell-height-limited">{parse(element.title)}</div></td>
                                                        <td>{element.code}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                    <div className="mt-4 mb-4" >
                                        <Paginator paginatorData={data} goToPage={loadPage} />
                                    </div>
                                </>
                            )}
                        </Container>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={loading} variant="secondary" onClick={onClose}>
                        Mudei de ideia
                    </Button>
                    <Button disabled={loading || !hasSelection} variant="primary" onClick={() => onSubmit()}>
                        Adicionar
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
}

export default AddQuestionModal;