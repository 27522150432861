import React from "react";
import { Container, Row, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";
import PageModal from "presentation/components/page-modal";

import AddTeacherModal from "presentation/pages/classrooms/elements/add-teacher-modal";

import { handleResultData } from "presentation/utils/functions";

import classroomsService from "presentation/services/classrooms";
import disciplinesEmployeesService from "presentation/services/disciplines-employees";

class ClassroomTeachersMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      actionLoading: false,
      error: false,
      message: '',
      data: [],
      classroom: {
        name: ''
      },
      showConfirmModal: false,
      showAddTeacherModal: false,
      targetDeleteId: null,
    };
    this.loadData = this.loadData.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
  }

  async componentDidMount() {
    const { id } = this.props.match.params
    await this.loadData(id);
  }

  loadData = async (id) => {

    await classroomsService.findById(id).then((result) => {
      let handledResult = handleResultData(result);

      this.setState({ classroom: handledResult });
    });

    await disciplinesEmployeesService.search({ classroomId: [parseInt(id)] }).then((result) => {
      let handledResult = handleResultData(result);
      let disc = {};
      let output = [];

      if (handledResult.length > 0) {
        for (let i = 0; i < handledResult.length; i++) {
          let element = handledResult[i];

          if (!disc[element.employeeId]) {
            let teacher = {
              id: element.employeeId,
              registration: element.employeeRegistration,
              name: element.employeeName,
              disciplines: [element]
            };
            disc[element.employeeId] = teacher;
          } else {
            disc[element.employeeId].disciplines.push(element);
          }
        }

        for (const teacherKey of Object.keys(disc)) {
          output.push(disc[teacherKey]);
        }
      }

      this.setState({ data: output, loading: false });
    });
  }

  openModal = (id) => {
    this.setState({ targetDeleteId: id, showConfirmModal: true });
  }

  closeModal = () => {
    this.setState({ showConfirmModal: false, targetDeleteId: null });
  }

  openAddTeacherModal = () => {
    this.setState({ showAddTeacherModal: true });
  }

  closeAddTeacherModal = () => {
    this.setState({ showAddTeacherModal: false });
  }

  deleteItem = () => {
    const { id } = this.props.match.params
    const { loadData } = this;
    if (this.state.targetDeleteId) {
      this.setState({ loading: true, actionLoading: true });
      disciplinesEmployeesService.remove(this.state.targetDeleteId).then((result) => {
        this.setState({ loading: false, actionLoading: false, targetDeleteId: null, showConfirmModal: false }, () => {
          loadData(id);
        });
      });
    }
  }

  render() {
    const { id } = this.props.match.params;

    const { loading, actionLoading, error, message, data, showConfirmModal, showAddTeacherModal, classroom } = this.state;

    const { deleteItem, closeModal, openModal, openAddTeacherModal, closeAddTeacherModal, loadData } = this;

    return (
      <div>
        {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
        {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
        {loading === false && error === false && data.length <= 0 && <PageAlert className="m-b-20" message={"Sem informações"} description={"Não há dados para exibir no momento..."} type="info" dismissible />}
        {loading === false && (
          <Container>
            <Row>
              <h4>Professores da turma {classroom.name} <Button variant="primary" onClick={() => openAddTeacherModal()} className="float-end">Adicionar</Button></h4>
              <Container>
                {
                  data.length > 0 && data.map((teacher, index) => {
                    return (
                      <div key={`teacher-${index}`}>
                        <h5>{teacher.name} (Matrícula:{teacher.registration})</h5>
                        <Table bordered borderless className="mt-4" >
                          <thead>
                            <tr>
                              <th>Disciplina</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {teacher.disciplines && teacher.disciplines.length > 0 && teacher.disciplines.map((element, index) => {
                              return (
                                <tr key={element.relationId}>
                                  <td>{element.disciplineName}</td>
                                  <td className="text-end">
                                    <Button variant="outline-secondary" onClick={() => openModal(element.relationId)}>Remover</Button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    )
                  })
                }
              </Container>
              <div className="mt-3">
                <Button as={Link} to={`/turmas`} className="float-end" variant="secondary">Mudei de ideia</Button>
              </div>
            </Row>
            <AddTeacherModal
              show={showAddTeacherModal}
              onClose={closeAddTeacherModal}
              loadData={loadData}
              entityId={id}
              entityName={classroom.name}
              entity={classroom}
            />
            <PageModal
              show={showConfirmModal}
              onClose={closeModal}
              onConfirm={deleteItem} />
          </Container >
        )
        }
      </div>
    );
  };
}

export default ClassroomTeachersMain;
