import questionOptionsService from "presentation/services/question-options";

// Question options methods
// Carrega uma alternativa no state
export const loadQuestionOption = (key, component) => {
    const { questionOptions } = component.state;
    let output = undefined;

    if (questionOptions.length > 0) {
        var questionOption = questionOptions.find(x => { return x.key == key });
        output = questionOption !== undefined ? questionOption : undefined;
    }

    return output;
}

//Carrega as informações de um campo específico da alternativa no state
export const questionOptionsFormValue = (key, formField, component) => {

    let output = undefined;

    var questionOption = loadQuestionOption(key, component);

    output = questionOption !== undefined ? questionOption[formField] : undefined;

    return output;

}

//Tratativas para quando um campo muda de valor (geral)
export const handleChangeQuestionOptions = (key, event, component) => {
    const { value, checked, type } = event.target;

    const name = event.target.getAttribute('data-field-name');

    const changeValue = type == "checkbox" ? checked : value;

    handleFieldChange(key, name, changeValue, component);
}

//Tratativas para quando um campo muda de valor (editor de texto)
export const handleChangeQuestionOptionsEditor = (key, formField, editor, component) => {
    const changeValue = editor.getData();

    handleFieldChange(key, formField, changeValue, component);
}

//Método genérico para acompanhar tratativas de mudança de valor dos campos
export const handleFieldChange = (key, field, value, component) => {
    var questionOption = loadQuestionOption(key, component);

    questionOption[field] = value;

    const newQuestionOptions = changeQuestionOptionsList(component, questionOption);

    let finalOptions = beforeChangeQuestionOptionsState(key, field, value, newQuestionOptions, component);

    component.setState({
        questionOptions: finalOptions
    });
}

//Método para executar tratamentos antes da mudança para o novo state do formulário
export const beforeChangeQuestionOptionsState = (key, changedField, changeValue, newQuestionOptions, component) => {
    const { form } = component.state;
    let output = newQuestionOptions;

    const allowMultipleAnswers = (form.allowMultipleOptionsAnswers || false);

    if (changedField == 'isCorrect' && changeValue == true && allowMultipleAnswers == false) {
        output = newQuestionOptions.map((option) => {
            let mapOutput = option;
            if (option.key !== key) {
                mapOutput[changedField] = false;
            }
            return mapOutput;
        });
    }

    return output;
}

//Prapara o objeto do state para a atualização com as informações das alternativas da questão
export const changeQuestionOptionsList = (component, targetOption, remove = false) => {
    let { questionOptions } = component.state;

    let optionIndex = questionOptions.findIndex(option => option.key == targetOption.key);

    if (remove === true) {
        let elementId = questionOptions[optionIndex]['id'] || undefined;

        if (elementId) {
            questionOptions[optionIndex]['isDeleteRequested'] = true;
            questionOptions[optionIndex]['key'] = 'delete-requested-' + new Date().getTime();
        } else {
            questionOptions.splice(optionIndex, 1);
        }
    } else {
        questionOptions[optionIndex] = targetOption;
    }

    return questionOptions;
}


//Prepara as requisições para administrar as alternativas das questões
export const prepareOptionsRequests = (questionEntity, questionOptionsData) => {
    let promises = undefined;
    let requests = [];

    questionOptionsData.map((e, index) => {

        let postData = {
            questionId: questionEntity.id
        };

        if (e.id) {
            postData.id = e.id;
            postData.insertAt = e.insertAt;

            if (e.isDeleteRequested) {
                postData.isDeleteRequested = true;
            }
        }

        postData = {
            ...postData,
            title: e.title,
            isCorrect: e.isCorrect,
            orderNumber: e.orderNumber
        }

        requests.push(postData);

    });

    if (requests.length > 0) {
        promises = requests.map(async (element, index) => {
            //Salva os dados da progressão
            if (element['isDeleteRequested']) {
                await questionOptionsService.remove(element['id']);
            } else {
                await questionOptionsService.save(element);
            }
        });
    };

    return promises;

}

// ----------------------------------------

//Verificação de cadastro feito incorretamente

//Mais de uma alternativa correta para uma mesma questão
export const hasMultipleInvalidCorrectAnswers = (component) => {
    const { form, questionOptions } = component.state;
    let output = false;
    const allowMultipleAnswers = (form.allowMultipleOptionsAnswers || false);

    if (allowMultipleAnswers === false) {
        let count = questionOptions.filter((option) => option.isCorrect == true && !option.isDeleteRequested).length;
        if (count > 1) {
            output = true;
        }
    }

    return output;

}

//Nenhuma alternativa correta na questão
export const hasNoCorrectAnswers = (component) => {
    const { questionOptions } = component.state;
    let output = false;

    let count = questionOptions.filter((option) => option.isCorrect == true && !option.isDeleteRequested).length;
    if (count === 0) {
        output = true;
    }

    return output;

}

const questionOptionsFunctions = {
    prepareOptionsRequests,
    loadQuestionOption,
    questionOptionsFormValue,
    handleChangeQuestionOptions,
    handleChangeQuestionOptionsEditor,
    beforeChangeQuestionOptionsState,
    changeQuestionOptionsList,
    hasMultipleInvalidCorrectAnswers,
    hasNoCorrectAnswers
}

export default questionOptionsFunctions;