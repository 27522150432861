import React from "react";
import { Form, Container, Row } from "react-bootstrap";

import {
  handleChangeQuestionInformations,
  questionInformationsFormValue
} from 'presentation/pages/activities/functions/activity-questions';

class QuestionPointsInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
    };
  }

  render() {
    let { show } = this.state;
    let { question, component } = this.props;

    let { questionOptions } = question;

    let correctOptions = [];
    if (typeof (questionOptions) !== 'undefined') {
      correctOptions = questionOptions.filter(x => x.isCorrect == true);
    }

    return (
      <Container>
        <Row>
          {correctOptions.map((e, i) => {
            let key = e.id;
            let questionPoints = questionInformationsFormValue(key, 'points', component);
            return (
              <div className="form mt-2" key={key}>
                <Form.Control
                  type="number"
                  id="points"
                  name="points"
                  data-field-name="points"
                  onChange={(event) => handleChangeQuestionInformations(key, event, component, { questionId: question.id, questionOptionId: e.id })}
                  defaultValue={questionPoints}
                />
              </div>
            );
          })}
        </Row>
      </Container>
    );
  };
}

export default QuestionPointsInput;