export const questionTypes = [
    { id: 1, name: 'Multipla escolha', machineName: 'multipleOptions' },
    { id: 2, name: 'Dissertativa', machineName: 'discursive' },
];

export const genders = [
    { id: 1, name: 'Masculino', machineName: 'male' },
    { id: 2, name: 'Feminino', machineName: 'female' },
    { id: 3, name: 'Outros', machineName: 'other' }
];

export const documentFieldTypes = [
    { id: 1, name: 'CPF', machineName: 'cpf' },
    { id: 2, name: 'CNPJ', machineName: 'cnpj' }
];
export const entitiesLabel = {
    student: "Aluno",
    employee: "Colaborador",
};

export const constants = {
    questionTypes: questionTypes,
    genders: genders,
    documentFieldTypes: documentFieldTypes,
    entitiesLabel: entitiesLabel
}

//Status do aluno na turma
export const CLASSROOM_STUDENT_STATUS_ACTIVE = 1;
export const CLASSROOM_STUDENT_STATUS_LOCKED = 2;
export const CLASSROOM_STUDENT_STATUS_REMOVED = 3;
export const CLASSROOM_STUDENT_STATUS_TRANSFERED = 4;
export const CLASSROOM_STUDENT_STATUS_COMPLETED = 5;

//Opções padrão
export const PERSON_LOOKUP_OPTION_NONE = "Não encontrei quem eu queria...";

export default constants;