import React from "react";
import { Modal, Button } from "react-bootstrap";

class PageModal extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { show, title, description, onClose, onConfirm, closeText, confirmText } = this.props;

        return (
            <Modal backdrop="static" show={show || false} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{title || "Exclusão de registro"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{description || "Tem certeza que deseja excluir o registro selecionado?"}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onClose}>
                        {closeText || "Mudei de ideia"}
                    </Button>
                    <Button variant="primary" onClick={onConfirm}>
                        {confirmText || "Confirmar"}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
}

export default PageModal;