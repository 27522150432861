import React from "react";
import { getUser } from "presentation/utils/authentication";
import StudentDashboard from "presentation/pages/principal/dashboards/students/main";
import TeacherDashboard from "presentation/pages/principal/dashboards/teachers/main";
import DefaultDashboard from "presentation/pages/principal/dashboards/default/main";

class MainPage extends React.Component {
  constructor(props) {
    super(props);

  }


  render() {

    const userInfo = getUser();

    return (
      userInfo.isTeacher == true ? <TeacherDashboard /> : (userInfo.isStudent == true ? <StudentDashboard /> : <DefaultDashboard />)
    );
  };
}

export default MainPage;