import moment from "moment";
import React from "react";
import { Modal, Button, Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

class StudentActivitiesModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            filterForm: {},
            selectedItems: [],
            data: []
        };
    }

    render() {
        let { show, onClose, activities, classroomName, disciplineName } = this.props;

        return (
            <Modal fullscreen backdrop="static" show={show || false} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title> {classroomName} / {disciplineName} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>

                        {activities.length > 0 && activities.map((element, index) => {
                            let alreadySelected = false;
                            return (
                                <Card key={element.activityId} className={alreadySelected ? 'active-card' : ''} >
                                    <Card.Body>
                                        <p className="lead">{element.activityTitle}</p>
                                        {element.activityEndDate && <small className="text-muted">Encerra em {moment(element.activityEndDate).format("DD/MM/YYYY HH:mm")}</small>}
                                        <Button disabled={element.activityWasAnswered} className="float-end" as={Link} to={{ pathname: `/atividades/responder/`, state: { activityId: element.activityId, studentId: element.studentId } }} variant={element.activityWasAnswered ? "success" : "outline-primary"}>{element.activityWasAnswered ? "Concluída" : "Responder"}</Button>{' '}
                                    </Card.Body>

                                </Card>
                            );
                        })}

                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onClose}>
                        Mudei de ideia
                    </Button>
                </Modal.Footer>
            </Modal >
        );
    };
}

export default StudentActivitiesModal;