import React from "react";
import PageHeader from "presentation/pages/layout/elements/page-header";


class ConfigurationManage extends React.Component{
  render() {
    let {location} = this.props;
    return (
      <div className="App">
        <PageHeader location={location}/>
      </div>
    );
  };
}

export default ConfigurationManage;
