import { createReducer } from '@reduxjs/toolkit';
import { add, clear } from 'presentation/redux/modules/sessionParams/actions';


const initialState = {
    data: {},
};

const sessionParams = createReducer(initialState, {
    [add]: (state, action) => {
        const { payload } = action;
        state.data = payload;
    },
    [clear]: (state, action) => {
        state.data = null;

    }
});

export default sessionParams;