import React, { useState } from 'react';
import { handleResultData } from "presentation/utils/functions";
import Turnstone from 'turnstone';

import employeesService from "presentation/services/employees";

const EmployeeSearchBox = ({ onSelect, onChange }) => {

    const styles = {
        input: 'form-control',
        inputFocus: 'form-control',
        query: 'text-oldsilver-800 placeholder-oldsilver-400',
        typeahead: 'text-crystal-500 border-white',
        cancelButton: `turnstone-cancel-button`,
        clearButton: 'turnstone-clear-button',
        listbox: 'w-100 turnstone-listbox',
        groupHeading: 'cursor-default mt-2 mb-0.5 px-1.5 uppercase text-sm text-hotpink-300',
        item: 'turnstone-listbox-item',
        highlightedItem: 'turnstone-listbox-hightlighted-item cursor-pointer p-1.5 text-lg overflow-ellipsis overflow-hidden text-oldsilver-700 rounded bg-crystal-100',
        match: 'font-semibold',
        noItems: 'cursor-default text-center my-20'
    };


    const listbox = {
        displayField: 'name',
        data: async (query) => {
            var postData = { searchName: query };
            var output = [];
            await employeesService.search(postData).then((result) => {
                let handledResult = handleResultData(result);
                const data = handledResult;
                output = data.map((e, i) => {
                    return { ...e, name: `${e.person.name} (Matrícula: ${e.registration || ''})` };

                });
            });
            return output;
        },
        searchType: 'startsWith',
    }

    return (
        <Turnstone
            onSelect={onSelect}
            onChange={onChange}
            styles={styles}
            id='employeeSearch'
            name='employeeSearch'
            autoFocus={true}
            typeahead={true}
            clearButton={true}
            debounceWait={250}
            listboxIsImmutable={false}
            maxItems={6}
            noItemsMessage="Não foram encontrados cadastros para esse nome..."
            placeholder='Digite um nome...'
            listbox={listbox}
        />
    )
}
export default EmployeeSearchBox