import React from "react";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import coursesService from "presentation/services/courses";
import academicPeriodsService from "presentation/services/academic-periods";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";

import { handleChange } from "presentation/utils/functions";

class ClassroomForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            localLoading: true,
            localError: false,
            message: '',
            courses: [],
            academicPeriods: [],
            topics: []
        }
    }

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {

        let academicPeriods = [];
        let courses = [];

        let localError = false;
        let localLoading = true;
        let errorMessages = [];
        let message = '';

        try {

            await coursesService.findAll().then(async (result) => {
                if (result.error == true) {
                    localError = true;
                    errorMessages.push("Não foi possível recuperar a listagem de cursos...");
                } else {
                    if (result.length <= 0) {
                        localError = true;
                        errorMessages.push("Por favor, cadastre cursos uma turma...");
                    } else {
                        courses = result;
                        await academicPeriodsService.findAll().then(async (academicPeriodsResults) => {
                            if (academicPeriodsResults.error == true) {
                                localError = true;
                                errorMessages.push("Não foi possível recuperar os períodos acadêmicos...");
                            } else {
                                if (academicPeriodsResults.length <= 0) {
                                    localError = true;
                                    errorMessages.push("Por favor, cadastre períodos acadêmicos, antes de cadastrar uma turma...");
                                } else {
                                    academicPeriods = academicPeriodsResults;
                                }
                            }
                        });
                    }
                }
            });

            if (localError === true) {
                localLoading = false;
                message = errorMessages.join(" / ");
            } else {
                localError = false;
                localLoading = false;

            }
            this.setState({ ...this.state, courses: courses, academicPeriods: academicPeriods, localError: localError, localLoading: localLoading, message: message });
        } catch (err) {
            this.setState({
                localLoading: false,
                localError: true,
                message: err.message
            });
        }
    }

    render() {
        var { localLoading, localError, message, courses, academicPeriods } = this.state;
        var { loading, form, handleSubmit, component } = this.props;


        return (
            <>
                {loading === true || localLoading === true && <PageLoadingIndicator loadingText={"Carregando informações..."} />}
                {(localLoading === false && localError === true) && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                {
                    loading === false && localLoading === false && (

                        <Form>
                            <h4>Informações da turma</h4>
                            <div className="form mt-2">
                                <Form.Label htmlFor="discipline">Período Acadêmico</Form.Label>
                                <Form.Select
                                    aria-label="Período Acadêmico"
                                    defaultValue={form.academicPeriodId}
                                    id="academicPeriodId"
                                    name="academicPeriodId"
                                    onChange={(e) => handleChange(e, component)}
                                    aria-describedby="academicPeriodIdHelpBlock"
                                    required
                                >
                                    <option>Selecione um período acadêmico</option>
                                    {academicPeriods.map((e, i) => { return <option key={e.id} value={e.id}>{e.name}</option> })}
                                </Form.Select>
                                <Form.Text id="academicPeriodIdHelpBlock" muted>
                                    Defina qual o período acadêmico da turma
                                </Form.Text>
                            </div>
                            <div className="form mt-2">
                                <Form.Label htmlFor="discipline">Curso</Form.Label>
                                <Form.Select
                                    aria-label="Curso"
                                    defaultValue={form.courseId}
                                    id="courseId"
                                    name="courseId"
                                    onChange={(e) => handleChange(e, component)}
                                    aria-describedby="courseIdHelpBlock"
                                    required
                                >
                                    <option>Selecione um curso</option>
                                    {courses.map((e, i) => { return <option key={e.id} value={e.id}>{e.name}</option> })}
                                </Form.Select>
                                <Form.Text id="courseIdHelpBlock" muted>
                                    Defina qual o curso da turma
                                </Form.Text>
                            </div>
                            <div className="form">
                                <Form.Label htmlFor="name">Nome da turma</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="name"
                                    name="name"
                                    defaultValue={form.name}
                                    onChange={(e) => handleChange(e, component)}
                                    aria-describedby="nameHelpBlock"
                                />
                                <Form.Text id="nameHelpBlock" muted>
                                    Informe nome da turma
                                </Form.Text>
                            </div>
                            <div className="form mt-2">
                                <Form.Label htmlFor="displayName">Nome de exibição da turma</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="displayName"
                                    name="displayName"
                                    defaultValue={form.displayName}
                                    onChange={(e) => handleChange(e, component)}
                                    aria-describedby="displayNameHelpBlock"
                                />
                                <Form.Text id="displayNameHelpBlock" muted>
                                    Escolha um nome de exibição
                                </Form.Text>
                            </div>
                            <div className="form mt-2">
                                <Form.Label htmlFor="displayName">Código da turma</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="code"
                                    name="code"
                                    defaultValue={form.code}
                                    onChange={(e) => handleChange(e, component)}
                                    aria-describedby="codeHelpBlock"
                                />
                                <Form.Text id="codeHelpBlock" muted>
                                    Escolha um código
                                </Form.Text>
                            </div>
                            <div className="mt-3">
                                <Button as={Link} to={'/turmas'} variant="secondary">Mudei de ideia</Button>
                                <Button disabled={loading || localError} variant="primary" className="float-end" onClick={() => handleSubmit()}>Salvar</Button>
                            </div>
                        </Form>
                    )
                }
            </>
        );
    };
}

export default ClassroomForm;