import activitiesService from "presentation/services/activities";
import { goTo } from "presentation/utils/functions";
import { prepareInformationsRequests, preparePointsInformationsRequests } from 'presentation/pages/activities/functions/activity-questions';

export const handleSubmit = async (component, returnTo) => {
    const { form, selectedQuestions, questionInformations } = component.state;
    component.setState({ loading: true, actionLoading: true, error: '' });


    const {
        title,
        disciplineId,
        classroomId,
    } = form;

    let errors = [];

    if (!classroomId) {
        errors.push("Por favor, preencha uma turma");
    }

    if (!disciplineId) {
        errors.push("Por favor, preencha um disciplina");
    }

    if (!title) {
        errors.push("Por favor, preencha um título para a atividade");
    }

    let questionOptionsSize = selectedQuestions.filter(x => !(x.isDeleteRequested == true)).length;
    if (questionOptionsSize <= 0) {
        errors.push("Você precisa adicionar questões para essa atividade");
    }


    if (errors.length > 0) {
        component.setState({ loading: false, error: true, message: errors.join("; ") });
    } else {
        let postData = component.state.form;
        try {
            const response = await activitiesService.save(postData);
            if (response.error == true) {
                component.setState({
                    loading: false,
                    actionLoading: false,
                    error: true,
                    message: response.message
                });
            } else {
                await prepareInformationsRequests(response, selectedQuestions).then(async () => {
                    await preparePointsInformationsRequests(response, questionInformations);
                });

                goTo(returnTo, component);
            }
        } catch (err) {
            component.setState({
                loading: false,
                actionLoading: false,
                error: true,
                message: err.message
            });
        }
    }
};

const activitiesFunctions = {
    handleSubmit,
}

export default activitiesFunctions;