export const APP_USER_KEY = "@dl-basicoapp-AppUserKey";
export const isAuthenticated = () => localStorage.getItem(APP_USER_KEY) !== null;
export const getToken = () => {
  var output = null, currentUser = getUser();
  if (currentUser !== null) {
    output = currentUser.token;
  }
  return output;
};

export const getUser = () => {
  var output = null, rawUser = localStorage.getItem(APP_USER_KEY);

  if (rawUser !== null) {
    var currentUser = JSON.parse(rawUser);
    output = currentUser;
  }

  return output;
};

export const setUser = userInfo => {
  var userToSave = JSON.stringify(userInfo);
  localStorage.setItem(APP_USER_KEY, userToSave);
};

export const identifyRoles = (userObj) => {
  var output = userObj;

  var userRoles = userObj.roles ? userObj.roles.map((e, i) => { return e.machineName }) : [];

  output.isMaster = userRoles.includes('master');
  output.isStudent = userRoles.includes('student');
  output.isResponsible = userRoles.includes('responsible');
  output.isTeacher = userRoles.includes('teacher');

  return output;
}

export const mergeWithSession = data => {
  var currentUser = getUser();
  setUser({ ...currentUser, ...data });
};

export const login = userInfo => {
  setUser(userInfo);
};

export const logout = async () => {
  await localStorage.removeItem(APP_USER_KEY);
};
