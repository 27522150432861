import React from "react";
import { Alert, Container, Row } from "react-bootstrap";

class PageAlert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true,
        };
    }

    render() {
        let { show } = this.state;
        let { message, description, dismissible, type } = this.props;

        if (show) {
            return (
                <Container>
                    <Row>
                        <Alert variant={type} onClose={() => this.setState({ show: false })} dismissible={dismissible || false
                        }>
                            <Alert.Heading>{message}</Alert.Heading>
                            {description && (<p>{description}</p>)}
                        </Alert >
                    </Row>
                </Container>
            );
        }
    };
}

export default PageAlert;