import React from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";

import DefaultEditor from "presentation/components/default-editor";

import QuestionTips from "presentation/pages/questions/elements/question-tips";
import RemoveAction from "presentation/pages/questions/elements/remove-action";

import {
    changeQuestionOptionsList,
    loadQuestionOption,
    handleChangeQuestionOptions,
    handleChangeQuestionOptionsEditor,
    questionOptionsFormValue
} from 'presentation/pages/questions/functions/question-options';

class QuestionOptionsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

    }

    componentDidMount() {
        this.mountOptions();
    }

    addOption = () => {
        let { component } = this.props;
        let { totalOptions } = component.state;
        component.setState({ ...component.state, totalOptions: totalOptions + 1 }, () => this.mountOptions());
    }
    renderRemoveConfirmation = (key) => {

    }

    removeAction = (key) => {
        let { component } = this.props;

        let { totalOptions } = component.state;

        let questionOptions = changeQuestionOptionsList(component, { key: key }, true);

        //reorganiza as keys
        let index = 0;
        questionOptions.map((element, i) => {
            if (!element.isDeleteRequested) {
                element.key = index + 1;
                index++;
            }
            return element;
        });

        component.setState({ ...component.state, totalOptions: totalOptions - 1, questionOptions: questionOptions }, () => this.mountOptions());
    }

    mountOptions = () => {
        let { component } = this.props;

        const { totalOptions, questionOptions } = component.state;

        const deleteRequested = questionOptions.filter(x => x.isDeleteRequested === true);

        let newQuestionOptions = [];

        for (let i = 0; i < totalOptions; i++) {

            let optionKey = i + 1;

            //Default values

            let defaultOptionTitle = '';
            let defaultOptionIsCorrect = false;
            let defaultQuestionId = null;
            let defaultQuestionOptionId = null;

            let optionTitle = defaultOptionTitle;
            let optionIsCorrect = defaultOptionIsCorrect;
            let optionId = defaultQuestionId;
            let optionQuestionId = defaultQuestionOptionId;

            let questionOption = loadQuestionOption(optionKey, component);

            if (questionOption !== undefined) {
                optionTitle = questionOption['title'] || defaultOptionTitle;
                optionIsCorrect = questionOption['isCorrect'] || defaultOptionIsCorrect;
                optionId = questionOption['id'] || defaultQuestionId;
                optionQuestionId = questionOption['questionId'] || defaultQuestionOptionId;
            }

            newQuestionOptions.push({
                key: optionKey,
                id: optionId,
                questionId: optionQuestionId,
                title: optionTitle,
                isCorrect: optionIsCorrect,
                orderNumber: i
            });
        }

        let finalQuestionOptions = [...newQuestionOptions, ...deleteRequested];

        component.setState({ ...component.state, questionOptions: finalQuestionOptions });
    }

    render() {
        let { component } = this.props;
        const { questionOptions, loading, totalOptions } = component.state;

        const { addOption, removeAction } = this;

        const validQuestionOptions = questionOptions.filter(x => !x.isDeleteRequested);

        return (
            <Container fluid className="question-options-container system-management">
                <Container>
                    <h3 className="mt-4">Informação das alternativas</h3>
                </Container>
                {validQuestionOptions.map((element, index) => {
                    let key = element.key;
                    let isCorrectValue = questionOptionsFormValue(key, 'isCorrect', component);
                    let titleValue = questionOptionsFormValue(key, 'title', component);
                    const deleteIsDisabled = (totalOptions < 3);
                    return (
                        <Container key={`question-option-${key}`} className={`mt-2 ${isCorrectValue == true ? 'option-correct-answer option-container' : 'option-incorrect-answer option-container'}`}>
                            <Row>
                                <Col className="mt-3 mb-3">
                                    <div>
                                        <Row>
                                            <Col>
                                                <h4>Alternativa  {key}</h4>
                                            </Col>
                                            <Col>
                                                <RemoveAction
                                                    component={this}
                                                    optionKey={key}
                                                    loading={loading}
                                                    deleteIsDisabled={deleteIsDisabled}
                                                    removeAction={removeAction} />
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="form mt-2">
                                        <Form.Label htmlFor={`isCorrect-${key}`}>É a opção correta?</Form.Label>
                                        <Form.Check
                                            type="switch"
                                            checked={isCorrectValue}
                                            label={isCorrectValue == true ? "Sim" : "Não"}
                                            id={`isCorrect-${key}`}
                                            name={`isCorrect-${key}`}
                                            data-field-name="isCorrect"
                                            onChange={(event) => handleChangeQuestionOptions(key, event, component)}
                                        />
                                    </div>
                                    <div className="form mt-2">
                                        <Form.Label htmlFor={`title-${key}`}>Resposta</Form.Label>
                                        <DefaultEditor
                                            id={`title-${key}`}
                                            name={`title-${key}`}
                                            data-field-name="title"
                                            data={titleValue || ""}
                                            onChange={(event, editor) => handleChangeQuestionOptionsEditor(key, 'title', editor, component)}
                                        />
                                        <Form.Text id={`title-${key}HelpBlock`} muted>
                                            Informe a resposta da alternativa  {key}
                                        </Form.Text>
                                    </div>
                                </Col>

                            </Row>
                        </Container>
                    );
                })}
                <div className="text-center">
                    <Button className="mt-5 mb-5" variant="primary" onClick={addOption}> Adicionar alternativa</Button>
                </div>
                <QuestionTips component={this} />
            </Container>
        );
    };
}

export default QuestionOptionsList;